import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Rating,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import ReactAudioPlayer from "react-audio-player";
import React from "react";
import { motion } from "framer-motion";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import {
  addOrder,
  cancelOrder,
  getOrderDetailsById,
  notifyAgain,
  orderItemApproveDisApprove,
  removedTransport,
  removedTransportProvider,
  resudleOrderOrder,
  submitEditedLink,
  swapProvider,
} from "../../../Apis/order_api";
import { getPStatus, getStatus } from "../../../Service/OrderService";
import OrderDailog from "../../../Component/Dailog/OrderDailog";
import { ToastShow } from "../../../Component/Toast/Toast";
import { API_URL } from "../../../Apis/config";
import { Check, Clear, ContentCopy, DoneOutline } from "@mui/icons-material";
import {
  dateFormateHHMMA,
  dateFormateMMDDYYY,
  dateFormateMMDDYYYHHMMSS,
} from "../../../Service/DateSevice";
import DateMDYHMA from "../../../Component/Date/DateMDYHMA";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { GETORDERISSUE } from "../../../Apis/order_issue_api";
import SolveOrderIssueDailog from "../../../Component/Dailog/SolveOrderIssueDailog";
import OrderChatPop from "../../../Component/PopOver/OrderChatPop";
import OrderChatPopAdmin from "../../../Component/PopOver/OrderAdminChat";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../Service/FirebaseInit";
import { rightsUser } from "../../Home/Drawer/DrawerView";
import { APPROVE_REJECT_EXTENDS, GET_MY_ROLES, GET_USER_RH } from "../../../Apis/users_api";
import ChangeStatus from "../../../Component/Dailog/ChangeStatus";
import { format, getDate } from "date-fns";
import ChangeOrderRecordDailog from "../../../Component/Dailog/ChangeOrderRecordDailog";
import AddLinkOrder from "../../../Component/Dailog/AddLinkOrder";
import { DELETE_LINK } from "../../../Apis/add_link_order";
import CancelDailog from "../../../Component/Dailog/OrderCancelDailog";
import OrderEditDateTime from "../../../Component/Dailog/OrderEditDateTime";
import AddInvoiceLinkOrder from "../../../Component/Dailog/AddOrderInvoiceLink";
import AddInvoicePayment from "../../../Component/Dailog/AddInvoicePaymentRecord";
import ShowInvoiceTransaction from "../../../Component/Dailog/ShowInvoiceTransaction";
import ButtonIcon2 from "../../../Component/Button/ButtonIcon2";
import BookingDetailsTable from "./OrderView Comps/BookingDetailsTable";
import OrderDetailsTable from "./OrderView Comps/OrderDetailsTable";
import ShootDetailsTable from "./OrderView Comps/ShootDetailsTable";
import PaymentDetailsBox from "./OrderView Comps/PaymentDetailsBox/PaymentDetailsBox";
import NoteTable from "./OrderView Comps/NoteTable";
import DataGrids from "./OrderView Comps/DataGrids";
import CustomerSubmitLink from "./OrderView Comps/CustomerSubmitLink/CustomerSubmitLink";
import AlreadySubmitted from "./OrderView Comps/CustomerSubmitLink/AlreadySubmitted";
import OrderSwapDailog from "../../../Component/Dailog/OrderSwapDailog";
import ButtonForGrid from "../../../Component/Button/ButtonForGrid";
import Rate from "../../../Assets//Rate.png";
import location_icon from "../../../Assets/Icons for pages/location_icon.png";
import swapIcon from "../../../Assets/Icons for pages/swapIcon.png";
import change_icon from "../../../Assets/Icons for pages/change_icon.png";
import OrderDailogPodian from "../../../Component/Dailog/OrderInterestedDailog";
import { ADD_SERVICE } from "../../../Apis/service_api";
import CancelReason from "./OrderView Comps/CancelReason";
import NotifyAgainDailog from "../../../Component/Dailog/NotifyAgainDailog";
import SplitButton from "../../../Component/Button/SplitButton";
import PlayVoice from "./OrderView Comps/PlayVoice";
import AmountAdjustModal from "./AmountAdjustModal";
import SwappedDailog from "../../../Component/Dailog/SwappedDailog";
import moment from "moment";
import board_icon from "../../../Assets/Icons for pages/board_icon.png";

import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import ShowLinkDetailsOrder from "../../../Component/Dailog/ShowLinkDetailsOrder";
import InvoiceModal from "./InvoiceModal";

function OrderView() {
  const navigator = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openPodian, setOpenPodian] = React.useState(false);
  const [orderItemId, setOrderItemId] = React.useState(0);
  const [orderItemIdPodian, setOrderItemIdPodian] = React.useState(0);
  const [qty, setQty] = React.useState(0);
  const [service, setService] = React.useState("");
  const [issue, setIssue] = React.useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const { id } = useParams();
  const [rowsMain, setRowsMain] = useState([]);
  const [rowsPre, setRowsPre] = useState([]);
  const [rowsPost, setRowsPost] = useState([]);

  const [uId, setUid] = useState(0);
  const [pids, setPids] = useState([]);

  function handleRows(data) {
    if (data?.items.length) {
      const mainService = data.items.filter(
        (obj) => obj.isFixed === 1 && obj.isExtra === 0 && obj.isPost === 0
      );
      if (mainService.length > 0) {
        setRowsMain(mainService);
      }

      const preService = data.items.filter(
        (obj) => obj.isExtra === 1 && obj.isFixed === 0 && obj.isPost === 0
      );
      if (preService.length > 0) {
        setRowsPre(preService);
      }

      const postService = data.items.filter(
        (obj) => obj.isExtra === 0 && obj.isFixed === 0
      );
      if (postService.length) {
        setRowsPost(postService);
      }
    }
  }

  function handleClickOpen(v, qty, service) {
    setOrderItemId(v);
    setQty(qty);
    setService(service);
    setOpen(true);
  }

  const handleClose = () => {
    setOrderItemId(0);
    setOpen(false);
  };

  const handleClosePodian = () => {
    setOrderItemIdPodian(0);
    setOpenPodian(false);
  };

  const handleSubmit = async (d, orderItemId) => {
    // const a = approve.join(",");
    // const d = disApprove.join(",");
    // console.log("ddd", a, d);

    setOrderItemId(0);
    setOpen(false);
    setIsLoading(true);

    const res = await orderItemApproveDisApprove(
      d.length ? d.join(",") : "0",
      orderItemId
    );

    if (res.s == 1) {
      ToastShow(res.m);
      getData();
    } else {
      getData();
      ToastShow(
        res && res.m
          ? res.m
          : "Something went wrong. Could you please try again."
      );
    }
  };

  const steps = [
    "Received/New Order",
    "Podian Interested",
    "Confirmed",
    "Order Started",
    "Finished",
    "RAW Received",
    "Delivery Successful",
  ];

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [submittedLink, setSubmittedLink] = useState("");

  //   ORDER ROW AND COLUMN
  const orderColumn = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },

    {
      field: "name",
      headerName: "Service",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Typography fontSize="12.4px">{params.value}</Typography>
            {params.row.discounts && (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {params.row.discounts.map((v) => (
                  <Typography
                    key={v.id}
                    sx={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    {v.msg}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <Typography fontSize="12.4px">{`₹${params.value}`}</Typography>
      ),
    },
    {
      field: "hours",
      headerName: "Hrs",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "qty",
      headerName: "Qty",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "remarks",
      headerName: "Notes",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 190,
      flex: 1,
      renderCell: (params) => {
        return params.row.remarks ? (
          <Typography fontSize="12.4px" sx={{
            textAlign: 'center'
          }}>{params.row.remarks}</Typography>
        ) : (
          <Typography fontSize="12.4px">N/A</Typography>
        );
      },
    },
    {
      field: "audio",
      headerName: "Voice Note",
      headerAlign: "center",
      align: "center",
      width: 190,
      flex: 1,
      renderCell: (params) => {

        return params?.row?.audio ? (
          // <ReactAudioPlayer
          //   style={{
          //     width: "190px",
          //   }}
          //   src={API_URL.baseUrl + API_URL.OrderServiceAudio + params.row.audio}
          //   autoPlay={false}
          //   controls
          // />
          <PlayVoice
            audioUrl={
              API_URL.baseUrl + API_URL.OrderServiceAudio + params?.row?.audio
            }
          />
        ) : (
          <Typography fontSize="12.4px">N/A</Typography>
        );
      },
    },
    {
      field: "interested",
      headerName: "Interested",
      headerAlign: "center",
      align: "center",
      width: 190,
      flex: 1,
      renderCell: (params) => {
        return <Typography fontSize="12.4px">{params.value}</Typography>;
      },
    },
    {
      field: "assigned",
      headerName: "Assigned",
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" flexDirection="column">
          {params.row.assigned ? (
            <Chip
              size="small"
              sx={{
                py: "0.9rem",
                backgroundColor: "#CEE5FF",
                borderRadius: "5px",
              }}
              label={
                <Typography fontSize="11.8px">
                  Assigned: {params.row.assigned}
                </Typography>
              }
            />
          ) : null}

          {params.row.working ? (
            <Chip
              size="small"
              sx={{
                py: "0.9rem",
                backgroundColor: "#CEE5FF",
                borderRadius: "5px",
              }}
              label={
                <Typography fontSize="11.8px">
                  Working: {params.row.working}
                </Typography>
              }
            />
          ) : null}

          {params.row.finished ? (
            <Chip
              size="small"
              sx={{
                py: "0.9rem",
                backgroundColor: "#CEE5FF",
                borderRadius: "5px",
              }}
              label={
                <Typography fontSize="11.8px">
                  Finished: {params.row.finished}
                </Typography>
              }
            />
          ) : null}
          {params.row.submitted ? (
            <Chip
              size="small"
              sx={{
                py: "0.9rem",
                backgroundColor: "#CEE5FF",
                borderRadius: "5px",
              }}
              label={
                <Typography fontSize="11.8px">
                  Submitted: {params.row.submitted}
                </Typography>
              }
            />
          ) : null}
          {params.row.edited ? (
            <Chip
              size="small"
              sx={{
                py: "0.9rem",
                backgroundColor: "#CEE5FF",
                borderRadius: "6px",
              }}
              label={
                <Typography fontSize="11.8px">
                  Edited: {params.row.edited}
                </Typography>
              }
            />
          ) : null}

          {!params.row.assigned &&
            !params.row.working &&
            !params.row.finished &&
            !params.row.submitted &&
            !params.row.edited && (
              <Typography fontSize="12.4px">N/A</Typography>
            )}
        </Box>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      // renderCell: (params) => (
      //   <Box my={0.5}>
      //     {showOrderViewRights?.some((elem) => elem?.name === "can see podian to assign" && elem?.id === 21) && (<ButtonForGrid
      //       label="Assign"
      //       onClick={() => {
      //         handleClickOpen(params.id, params.row.qty, params.row.name);
      //       }}
      //       isDisable={
      //         params.row.status > 3
      //           ? true
      //           : params.row.isProviderRequired == 1
      //             ? false
      //             : true
      //       }
      //     />)}
      //     {showOrderViewRights?.some((elem) => elem?.name === "can assign podian" && elem?.id === 22) && (<ButtonForGrid
      //       label="Assign"
      //       onClick={() => {
      //         handleClickOpen(params.id, params.row.qty, params.row.name);
      //       }}
      //       isDisable={
      //         params.row.status > 3
      //           ? true
      //           : params.row.isProviderRequired == 1
      //             ? false
      //             : true
      //       }
      //     />)}
      //   </Box>
      // ),
      renderCell: (params) => {
        const renderButton = () => {
          if (showOrderViewRights?.some((elem) => elem?.name === "can see podian to assign" && elem?.id === 21)) {
            return (
              <ButtonForGrid
                label="Assign"
                onClick={() => {
                  handleClickOpen(params?.id, params?.row?.qty, params?.row?.name);
                }}
                isDisable={
                  params.row.status > 3
                    ? true
                    : params.row.isProviderRequired === 1
                    ? false
                    : true
                }
              />
            );
          }
      
          if (showOrderViewRights?.some((elem) => elem?.name === "can assign podian" && elem?.id === 22)) {
            return (
              <ButtonForGrid
                label="Assign"
                onClick={() => {
                  handleClickOpen(params.id, params.row.qty, params.row.name);
                }}
                isDisable={
                  params.row.status > 3
                    ? true
                    : params.row.isProviderRequired === 1
                    ? false
                    : true
                }
              />
            );
          }
      
          return null;
        };
      
        return <Box my={0.5}>{renderButton()}</Box>;
      },
      
    },
  ];
  console.log('datadatadatadatadata', data);

  const invoiceColumn = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },

    {
      field: "name",
      headerName: "Name",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "price",
      headerName: "Price",
      type: "string",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <Typography>{`₹${params.value}`}</Typography>,
    },
    {
      field: "qty",
      headerName: "Qty",
      type: "string",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "total_amount",
      headerName: "T. Amount",
      type: "string",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <Typography>{`₹${params.value}`}</Typography>,
    },
    {
      field: "refund_amount",
      headerName: "Ref. Amount",
      type: "string",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <Typography>{`₹${params.value}`}</Typography>,
    },
    {
      field: "paid_amount",
      headerName: "P. Amount",
      type: "string",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <Typography>{`₹${params.value}`}</Typography>,
    },
    {
      field: "add_link",
      headerName: "Add Link",
      type: "string",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            {params.row.status == 1 && params.row.isRequiredEditedData == 1 ? (
              <Button onClick={() => handleOpenAddLink(params.row)}>
                {params.row.linkEdited.length > 0 ? "Uploaded" : "Add Link"}
              </Button>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      field: "payment_record",
      headerName: "Add Payment Record",
      type: "string",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            {+params.row.paid_amount <= 0 ? (
              <Button onClick={() => handleOpenChangePayment(params.row)}>
                Add Payment Record
              </Button>
            ) : (
              "-"
            )}
          </>
        );
      },
    },

    {
      field: "download",
      headerName: "Download",
      type: "string",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={() => {
                axios
                  .get(API_URL.baseUrl + "iipdf/" + params.row.id, {
                    responseType: "arraybuffer",
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/pdf",
                    },
                  })
                  .then((response) => {
                    const url = window.URL.createObjectURL(
                      new Blob([response.data])
                    );

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                      "download",
                      `invoice_${params.row.id}.pdf`
                    ); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                  })
                  .catch((error) => console.log(error));
                //  window.open(API_URL.baseUrl + "iipdf/"+params.row.id,"_blank");
              }}
            >
              Download
            </Button>
          </>
        );
      },
    },

    {
      field: "payments",
      headerName: "Transaction",
      type: "string",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            {params.value.length ? (
              <Button
                variant="text"
                onClick={() => {
                  handleOpenIT(params.value);
                }}
              >
                Show Transaction{" "}
              </Button>
            ) : (
              "-"
            )}
          </>
        );
      },
    },

    {
      field: "date_time",
      headerName: "P. DateTime",
      type: "string",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.row.payments.length > 0 ? (
          <DateMDYHMA date={params.value} />
        ) : (
          "-"
        );
      },
    },

    {
      field: "created_at",
      headerName: "Created At",
      type: "string",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} />;
      },
    },
  ];

  const [itData, setITData] = useState([]);

  const [openIT, setOpenIT] = useState(false);

  const handleOpenIT = (v) => {
    setITData(v);
    setOpenIT(true);
  };

  const handleCloseIT = () => {
    setITData([]);
    setOpenIT(false);
  };

  const issueColumn = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },

    {
      field: "title",
      headerName: "Title",
      width: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "message",
      headerName: "Message",
      type: "string",
      width: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "response",
      headerName: "Response",
      type: "string",
      width: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      type: "number",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography>{params.value == 1 ? "Solved" : "Pending"}</Typography>
      ),
    },
    {
      field: "reported_dt",
      headerName: "Reported date",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 250,
      flex: 1,
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} />;
      },
    },
    {
      field: "done_dt",
      headerName: "Solved date",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 200,
      flex: 1,
      renderCell: (params) => {
        return params.row.status == 1 ? (
          <DateMDYHMA date={params.value} />
        ) : (
          "-"
        );
      },
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   type: "number",
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return params.row.status == 0 ? (
    //       <Button
    //         variant="outlined"
    //         onClick={() => {
    //           setSelectedIssueId(params.row.id);
    //           setOpenIssue(true);
    //         }}
    //       >
    //         Solve
    //       </Button>
    //     ) : null;
    //   },
    // },
  ];

  const [selectedIssueId, setSelectedIssueId] = useState(0);
  const [openIssue, setOpenIssue] = useState(false);

  const handelClose = (v) => {
    if (v) {
      getIssueData();
    }
    setOpenIssue(false);
  };

  const getData = async () => {
    setIsLoading(true);
    const data = await getOrderDetailsById(id);

    setData(data.r);
    handleRows(data.r);
    setIsLoading(false);
  };

  const handleSubmitedLink = (v) => {
    setSubmittedLink(v);
  };

  const updateLink = async () => {
    if (submittedLink) {
      const v = data.edited_link ? JSON.parse(data.edited_link) : [];
      v.push(submittedLink);
      const res = await submitEditedLink(id, v);

      if (res && res.s) {
        setSubmittedLink("");
        getData();
        ToastShow("Success");
      }
    } else {
      ToastShow("Please enter link");
    }
  };

  const getIssueData = async () => {
    const d = await GETORDERISSUE(id);
    if (d && d.s && d.r) {
      setIssue([...d.r]);
    }
  };

  useEffect(() => {
    getData();
    handleRows();
    getIssueData();
  }, []);

  // useEffect(() => {
  //   handleRows();
  // }, [data]);

  const [anchorEl, setAnchorEl] = React.useState();
  const [anchorElMsg, setAnchorElMsg] = React.useState();
  const [providerId, setProviderId] = React.useState(0);

  const handleClickPop = (event) => {
    const d = data?.providers?.map((e) => e.user_id);
    setPids([...d]);

    setUid(data?.user_id);
    setAnchorEl(event.currentTarget);
  };

  const handleClickPopMsg = (event) => {
    setAnchorElMsg(event.currentTarget);
  };

  const handleClosePop = () => {
    setAnchorEl(null);
  };

  const handleClosePopMsg = () => {
    setProviderId(0);
    setAnchorElMsg(null);
  };

  const [openConfirm, setOpenConfirm] = useState(false);

  const handleCloseConfirm = async (v, r) => {
    setOpenConfirm(false);

    if (v) {
      await cancelOrder(id, r);
      ToastShow("Order cancel successfully");
      getData();
    }
  };

  const openPop = Boolean(anchorEl);
  const idPop = openPop ? "simple-popover" : undefined;

  const openPopMsg = Boolean(anchorElMsg);
  const idPopMsg = openPopMsg ? "simple-popover-msg" : undefined;
  const [notifyAgainOpen, setNotifiyAgainOpen] = useState(false);
  const setRemovedTransport = async (status) => {
    const data = await removedTransport(id, status);

    if (data && data.s) {
      getData();
    }
  };

  const notifyAgains = async () => {
    if (data?.items?.length == 1) {
      const data = await notifyAgain(id);
      if (data && data) {
        ToastShow("Successfully notify");
      }
    } else {
      setNotifiyAgainOpen(true);
    }
  };

  const navigation = useNavigate();

  const reorder = async () => {
    const data = await getOrderDetailsById(id);

    if (data) {
      const d = {
        user_id: data.r.user_id,
        city_id: data.r.city_id,
        date_time: data.r.date_time,
        remarks: data.r.remar ?? "",
        shoot_area: data.r.shoot_area,
        address: data.r.address,
        near_by_address: data.r.near_by_address,
        latitude: data.r.latitude,
        longitude: data.r.longitude,
        gst_no: data.r.gst_no,
        address1: data.r.address1,
        business_name: data.r.business_name,
        billing_address: data.r.billing_address,
        is_for_someone: data.r.is_for_someone,
        someone_name: data.r.someone_name,
        someone_mobile: data.r.someone_mobile,
        is_pod_can_use: data.r.is_pod_can_use,
        category_id: data.r.category_id,
        sub_category_id: data.r.sub_category_id,
        sub_category_name: data.r.sub_category_name,
        isCollaboration: data.r.isCollab,
      };

      const res = await addOrder(d);

      if (res && res.s) {
        for (const i of data.r.items) {
          const d = {
            orders_id: res.r.orders_id,
            service_id: i.service_id,
            qty: i.qty ?? 1,
            hours: i.hours ?? 1,
            orders_items_id: 0,
            provider_cut: i.provider_cut ?? 0,
          };
          await ADD_SERVICE(d);
        }

        ToastShow("Order created successfully");

        // ADD SERVICE
        navigation("/order/" + res.r.orders_id + "/draft");
      } else {
        ToastShow("Oops! something went wrong.");
      }
    }
  };

  const [isOrderConfirm, setOrderConfirm] = useState(false);

  const [openRs, setOpenRs] = useState(false);
  const [rsData, setRsData] = useState(null);

  const [openAddlink, setOpenAddLink] = useState(false);
  const [raw, setRaw] = useState(null);

  const [changeOpen, setChangeOpen] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [showOrderViewRights, setShowOrderViewRights] = useState([]);
  const changePaymentRecordClose = (v) => {
    setChangeOpen(false);
    if (v) {
      getData();
    }
  };

  const handleCloseAddlink = (v) => {
    if (v) {
      getData();
    }
    setRaw(null);
    setOpenAddLink(false);
  };

  const handleClosePaymentChange = (v) => {
    if (v) {
      getData();
    }
    setRaw(null);
    setChangeOpen(false);
  };

  const handleOpenAddLink = (v) => {
    setRaw(v);
    setOpenAddLink(true);
  };

  const handleOpenChangePayment = (v) => {
    setRaw(v);
    setChangeOpen(true);
  };

  const handleCloseRs = async (v, d) => {
    setOpenRs(false);
    if (v) {
      const res = await resudleOrderOrder({
        date_time: d,
        orders_id: rsData.id,
      });
      if (res && res.s) {
        ToastShow(res.m);
        await getData();
      } else {
        ToastShow(res.m ?? res.m);
      }
    }
  };

  const handleRegenerateOrder = (v) => {
    setOrderConfirm(false);
    if (v) {
      reorder();
    }
  };
  useEffect(() => {
    getMyRoles();
  }, [])
  const getMyRoles = async () => {
    const res = await GET_MY_ROLES();
    if (res && res.s) {
      console.log('res', res?.r?.rights);
      setShowOrderViewRights(res?.r?.rights)
    } else {
      ToastShow(res.m);
    }
  };
  if (isLoading) {
    return (
      <Box
        height="100vh"
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  return (
    <>
      {isLoading ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="Center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {/*1st Container*/}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex">
              <Typography fontSize="1.1rem" fontWeight="500">
                Order Details
              </Typography>
              <Typography
                fontSize="1.1rem"
                fontWeight="500"
                pl="0.3rem"
                pr="0.8rem"
              >
                #{id}
              </Typography>
              <Typography fontSize="1.1rem" fontWeight="500">
                OTP - {data.otp}
              </Typography>
            </Box>

            <Stack direction="row" gap="1rem">
              <div>
                {/* {showOrderViewRights?.map((elem) => elem?.name === "can see invoice") < ButtonIcon2
                  label="Invoice"
                icon={board_icon}
                paddingX="1.1rem"
                onClick={(event) => setOpenInvoice(event.currentTarget)}
                boxShadow={true}
                />} */}
                {showOrderViewRights?.some((elem) => elem?.name === "can see invoice" && elem?.id === 23) && (
                  <ButtonIcon2
                    label="Invoice"
                    icon={board_icon}
                    paddingX="1.1rem"
                    onClick={(event) => setOpenInvoice(event.currentTarget)}
                    boxShadow={true}
                  />
                )}

                <Menu
                  id="menu"
                  anchorEl={openInvoice}
                  open={openInvoice}
                  onClose={() => setOpenInvoice(false)}
                  style={{ marginTop: "0.3rem" }}
                >
                  {/*View Invoice*/}
                  <MenuItem
                    style={{ fontSize: "14px" }}
                    onClick={() => {
                      axios
                        .get(API_URL.baseUrl + "ipdf/" + id, {
                          responseType: "arraybuffer",
                          headers: {
                            "Content-Type": "application/json",
                            Accept: "application/pdf",
                          },
                        })
                        .then((response) => {
                          const blob = new Blob([response.data], {
                            type: "application/pdf",
                          });
                          const url = window.URL.createObjectURL(blob);
                          window.open(url, "_blank");
                        })
                        .catch((error) => console.log(error));

                      setOpenInvoice(false);
                    }}
                  >
                    View Invoice
                  </MenuItem>

                  {/*Download Invoice*/}
                  <MenuItem
                    style={{ fontSize: "14px" }}
                    onClick={() => {
                      axios
                        .get(API_URL.baseUrl + "ipdf/" + id, {
                          responseType: "arraybuffer",
                          headers: {
                            "Content-Type": "application/json",
                            Accept: "application/pdf",
                          },
                        })
                        .then((response) => {
                          const url = window.URL.createObjectURL(
                            new Blob([response.data])
                          );
                          const link = document.createElement("a");
                          link.href = url;
                          link.setAttribute("download", `invoice_${id}.pdf`);
                          document.body.appendChild(link);
                          link.click();
                        })
                        .catch((error) => console.log(error));

                      setOpenInvoice(false);
                    }}
                  >
                    Download Invoice
                  </MenuItem>
                </Menu>
              </div>

              <Button
                sx={{
                  bgcolor: "white",
                  boxShadow: "3px 3px 8px 0px #00000040",
                  borderRadius: "6px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  minWidth: "0",
                  "&:hover": {
                    bgcolor: "#bfbebb",
                  },
                }}
                id={idPop}
                onClick={handleClickPop}
              >
                <img
                  src={require("../../../Assets/Chat.png")}
                  style={{ width: "19px", height: "19px", objectFit: "fill" }}
                  alt=""
                />
              </Button>
              <div>
                <Button
                  sx={{
                    height: "100%",
                    bgcolor: "white",
                    boxShadow: "3px 3px 8px 0px #00000040",
                    borderRadius: "6px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                    minWidth: "0",
                    "&:hover": {
                      bgcolor: "#bfbebb",
                    },
                  }}
                  onClick={(event) => setOpenMenu(event.currentTarget)}
                >
                  <img
                    src={require("../../../Assets/Icons for pages/three_dots.png")}
                    style={{ width: "18px", objectFit: "fill" }}
                    alt=""
                  />
                </Button>
                <Menu
                  id="menu"
                  anchorEl={openMenu}
                  open={openMenu}
                  onClose={() => setOpenMenu(false)}
                  style={{ marginTop: "0.3rem" }}
                >
                  {/*Reschedule Order*/}
                  {rightsUser.includes(2) ? (
                    <MenuItem
                      style={{ fontSize: "14px" }}
                      onClick={() => {
                        setOpenMenu(false);
                        if (data.paid_amount > 0) {
                          setRsData(data);

                          setOpenRs(true);
                        } else {
                          navigator(`edit`);
                        }
                      }}
                    >
                      Edit
                    </MenuItem>
                  ) : null}
                  {/*Cancel Order*/}
                  {data.status != -1 && data.status != 7 ? (
                    <MenuItem
                      style={{ fontSize: "14px" }}
                      onClick={() => setOpenConfirm(true)}
                    >
                      Cancel Order
                    </MenuItem>
                  ) : null}

                  {/*Re-Order*/}
                  {(data.status == -1 || data.status == 7) && (
                    <MenuItem
                      style={{ fontSize: "14px" }}
                      onClick={() => setOrderConfirm(true)}
                    >
                      Re-Order
                    </MenuItem>
                  )}
                </Menu>
              </div>
            </Stack>
          </Stack>

          {/*Stepper*/}
          <Box
            sx={{
              bgcolor: "white",
              boxShadow: "3px 3px 7px 0px #00000040",
              borderRadius: "6px",
              paddingTop: "0.8rem",
              paddingBottom: "1rem",
            }}
          >
            <Stepper
              activeStep={data.status}
              alternativeLabel
              sx={{
                width: "100%",
                "& .css-50ry68-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed, .css-50ry68-MuiSvgIcon-root-MuiStepIcon-root.Mui-active":
                  { fontSize: "1.2rem" },
                "& .css-50ry68-MuiSvgIcon-root-MuiStepIcon-root": {
                  fontSize: "1.2rem",
                },
              }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>
                    <Typography fontSize="11.9px" fontWeight="500">
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          {/*Cancel Reason*/}
          {data.status == -1 ? (
            <CancelReason
              cancel_dt={data?.cancel_dt}
              cancel_reason={data.cancel_reason}
            />
          ) : null}

          {data.is_repeat_previous_provider ? (
            <Box mt="0.2rem">
              <Typography
                sx={{
                  display: "inline",
                  bgcolor: "#ea2d2d1e",
                  borderRadius: "25px",
                  fontSize: "14px",
                  p: 1,
                }}
              >
                Requested for previous podian
              </Typography>
            </Box>
          ) : null}

          {/*3rd Container*/}
          <Stack direction="row" width="100%" gap="1rem">
            <Stack flex={1} justifyContent="space-between">
              {rightsUser.includes(3) ? (
                <BookingDetailsTable
                  id={data.user_id}
                  name={`${data.first_name ?? ""} ${data.last_name ?? ""}`}
                  phone={`${data.mobile ?? "-"}`}
                  for_someone_name={
                    data.is_for_someone ? data.someone_name : null
                  }
                  for_someone_num={
                    data.is_for_someone ? data.someone_mobile : null
                  }
                  gst_no={data.gst_no}
                  business_name={data.business_name}
                  booking_time={data.created_at}
                />
              ) : null}

              <OrderDetailsTable
                orderID={id}
                reschedule_count={
                  // data.reschedule > 0 ? <DisplayReschedule id={id} /> : "0"
                  data.reschedule > 0 ? data.reschedule : "0"
                }
                edit_count={data.items.length}
                social_permission={data.is_pod_can_use ? "Yes" : "No"}
              />
            </Stack>
            <Box>
              <ShootDetailsTable
                categories={data.category_name}
                subCategories={data.sub_category_name}
                date={dateFormateMMDDYYY(data.date_time)}
                time={dateFormateHHMMA(data.date_time)}
                shooting={data.shoot_area ? "Outdoor" : "Indooor"}
                city={data.city}
                address={data.address}
                landmark={data.near_by_address}
                flatNo_floor_society={data.address1}
                latitude={data.latitude}
                longitude={data.longitude}
              />
            </Box>
            <Box width="100%">
              {/*According to isPaymentPaid this component will be displayed*/}
              <PaymentDetailsBox
                isPaymentPaid={getPStatus(data.finalAmount)}
                totalAmount={`₹${data.sub_total.toFixed(2)}`}
                finalAmount={`₹${data.total_amount.toFixed(2)}`}
                data={data}
                // distanceCharges={
                //   data?.charges[0]
                //     ? `₹${data?.charges[0].amount.toFixed(2)}`
                //     : "N/A"
                // }
                // distanceCharges_desc={
                //   data?.charges[0] ? `${data?.charges[0].description}` : null
                // }
                // sgst={
                //   data?.charges[1]
                //     ? `₹${data?.charges[1].amount.toFixed(2)}`
                //     : "N/A"
                // }
                // cgst={
                //   data?.charges[2]
                //     ? `₹${data?.charges[2].amount.toFixed(2)}`
                //     : "N/A"
                // }
                netAmount={`₹${data.finalAmount.toFixed(2)}`}
                isRemoveTransport={data.isRemoveTransportCharge}
                handleRemovedTransport={setRemovedTransport}
                paidAmount={data.paid_amount}
                transactionDetails_rows={data.payments}
                id={id}
                getData={getData}
              />
            </Box>
          </Stack>

          {/*Invoices*/}
          {/* {data?.invoices?.length ? (
            <Stack>
              <Box
                width="100%"
                display="flex"
                justifyContent="start"
                alignItems="center"
              >
                <Typography variant="h6" ml={1}>
                  Invoices Details
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  boxShadow: "3px 3px 7px 0px #00000040",
                }}
              >
                <DataGrideView
                  rows={data.invoices}
                  columns={invoiceColumn}
                  autoHeight={true}
                />
              </Box>
            </Stack>
          ) : null} */}

          {data.invoices && (
            <Swiper
              modules={[Navigation, Scrollbar, Pagination]}
              spaceBetween={10}
              slidesPerView={4}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
              style={{
                width: "100%",
              }}
            >
              {data.invoices.map((v, index) => {
                return (
                  <SwiperSlide key={index} style={{ width: "20vw" }}>
                    <InvoiceDetailsView item={v} getData={getData} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}

          {/*Note*/}
          {data.remarks || data.audio ? (
            <NoteTable remarks={data.remarks ?? "-"} audioUrl={data.audio} />
          ) : null}

          {data.status >= 5 ? (
            <SubmitedLink
              status={data.status}
              handleSubmitedLink={handleSubmitedLink}
              submittedLink={submittedLink}
              updateLink={updateLink}
              value={data.linkEdited}
              id={id}
              getData={getData}
            />
          ) : null}

          {issue.length ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                overflow: "clip",
              }}
            >
              <Typography variant="h6">Issues</Typography>

              <Box
                sx={{ boxShadow: "3px 3px 7px 0px #00000040", width: "100%" }}
              >
                <DataGrideView
                  rows={issue}
                  columns={issueColumn}
                  autoHeight={true}
                />
              </Box>
            </Box>
          ) : null}

          {rightsUser.includes(6) ? (
            <ProviderView
              getData={getData}
              data={data}
              handleClickPopMsg={handleClickPopMsg}
              setProviderId={setProviderId}
              anchorElMsg={anchorElMsg}
            />
          ) : null}

          {/*Data Grids*/}
          <DataGrids
            rowsPre={rowsPre}
            rowsMain={rowsMain}
            rowsPost={rowsPost}
            data_status={data.status}
            orderColumn={orderColumn}
            handleNotifyAgain={notifyAgains}
          />
        </Box>
      )}

      {/*For Chat*/}
      {openPop && (
        <OrderChatPop
          id={idPop}
          anchorEl={anchorEl}
          handleClose={handleClosePop}
          open={openPop}
          oId={id}
          uId={uId}
          pIds={pids}
        />
      )}

      {/*To Reschedule Order*/}
      {openRs && (
        <OrderEditDateTime
          handleOnClose={handleCloseRs}
          open={openRs}
          data={rsData}
        />
      )}

      {/*To Cancel Order*/}
      {openConfirm && (
        <CancelDailog
          open={openConfirm}
          handleOnClose={handleCloseConfirm}
          title="Cancel Order!"
          content="Are you sure, You want to cancel Order?"
        />
      )}

      {/*Assign*/}
      {orderItemId ? (
        <OrderDailog
          status={data.status}
          open={open}
          handleClose={handleClose}
          orderItemId={orderItemId}
          qty={qty}
          service={service}
          handleSubmit={handleSubmit}
        />
      ) : null}

      {/*Podian card chat*/}
      {openPopMsg && (
        <OrderChatPopAdmin
          id={idPopMsg}
          anchorEl={anchorElMsg}
          handleClose={handleClosePopMsg}
          open={openPopMsg}
          oId={id}
          pId={providerId}
          uId={data?.user_id ?? 0}
        />
      )}

      {orderItemIdPodian ? (
        <OrderDailogPodian
          open={openPodian}
          handleClose={handleClosePodian}
          orderItemId={orderItemIdPodian}
        />
      ) : null}

      {openIssue && (
        <SolveOrderIssueDailog
          open={openIssue}
          handelClose={handelClose}
          id={selectedIssueId}
        />
      )}

      {openConfirm && (
        <CancelDailog
          open={openConfirm}
          handleOnClose={handleCloseConfirm}
          title="Cancel order!"
          content="Are you sure, You want to cancel order?"
        />
      )}

      {isOrderConfirm && (
        <ConfirmationDailog
          open={isOrderConfirm}
          handleOnClose={handleRegenerateOrder}
          title="Re generate order!"
          content="Are you sure, You want to regenerate order?"
        />
      )}

      {/* Invoices */}
      {openAddlink && (
        <AddInvoiceLinkOrder
          open={openAddlink}
          handleOnClose={handleCloseAddlink}
          row={raw}
          id={raw?.linkEdited?.length > 0 ? raw?.linkEdited[0].id : 0}
          order_ids={raw?.id ?? 0}
        />
      )}

      {changeOpen && (
        <AddInvoicePayment
          open={changeOpen}
          id={raw?.id ?? 0}
          handleOnClose={handleClosePaymentChange}
        />
      )}

      {openIT && (
        <ShowInvoiceTransaction
          open={openIT}
          handleClose={handleCloseIT}
          data={itData}
        />
      )}

      {notifyAgainOpen && (
        <NotifyAgainDailog
          open={notifyAgainOpen}
          data={data?.items ?? []}
          handleOnClose={(v) => setNotifiyAgainOpen(false)}
          id={data.id}
        />
      )}
    </>
  );
}

export default OrderView;

function InvoiceDetailsView({ item, getData }) {
  const [openAddlink, setOpenAddLink] = useState(false);
  const [openIT, setOpenIT] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [changeOpen, setChangeOpen] = useState(false);

  const handleOpenAddLink = (v) => {
    setOpenAddLink(true);
  };

  const handleCloseAddlink = (v) => {
    if (v) {
      getData();
    }
    setOpenAddLink(false);
  };

  const handleOpenIT = (v) => {
    setOpenIT(true);
  };

  const handleCloseIT = () => {
    setOpenIT(false);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const handleOpenChangePayment = (v) => {
    setChangeOpen(true);
  };
  const handleClosePaymentChange = (v) => {
    if (v) {
      getData();
    }
    setChangeOpen(false);
  };

  return (
    <Box
      width="100%"
      boxShadow="3px 3px 7px 0px #00000040"
      sx={{
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
        width: "100%",
        cursor: "grab",
      }}
    >
      {/*Header*/}
      <Box
        sx={{
          width: "100%",
          bgcolor: "#FFAB30",
          height: "2.370rem",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography fontSize="15px" fontWeight="500" ml="0.8rem">
          Invoice Details
        </Typography>

        <Button
          sx={{
            textTransform: "none",
            bgcolor: "white",
            mr: "0.1rem",
            "&:hover": { bgcolor: "#DFDFDF" },
          }}
          onClick={() => {
            axios
              .get(API_URL.baseUrl + "iipdf/" + item.id, {
                responseType: "arraybuffer",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/pdf",
                },
              })
              .then((response) => {
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );

                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `invoice_${item.id}.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
              })
              .catch((error) => console.log(error));
          }}
        >
          <Typography fontSize="15px">Download Invoice</Typography>
        </Button>
      </Box>
      {/*Rows*/}
      <Row label="Name" prop={item.name} />
      <Row label="Price" prop={`₹${item.price}`} />
      <Row label="Qty" prop={item.qty} />
      <Row label="T.amount" prop={`₹${item.total_amount}`} />
      <Row label="Ref.amount" prop={`₹${item.refund_amount}`} />
      <Row label="P.amount" prop={`₹${item.paid_amount}`} />
      <Row label="Status" prop={item.finalAmount <= 0 ? "PAID" : "UNPAID"} />
      <Row
        // showDetails={!!item.linkEdited.length}
        showDetails={false}
        clickOnDetails={() => {
          setOpenInfo(true);
        }}
        label="Add Link"
        prop={
          item.status > 0 && item.isRequiredEditedData == 1
            ? item.linkEdited.length > 0
              ? "Uploaded"
              : "Add Link"
            : "-"
        }
        isClick={item.status > 0 && item.isRequiredEditedData == 1}
        callback={
          item.status > 0 && item.isRequiredEditedData == 1
            ? () => handleOpenAddLink(item)
            : null
        }
      />
      <Row
        label="Transaction"
        prop={item.payments.length > 0 ? "Show Transaction" : "-"}
        isClick={item.payments.length}
        callback={item.payments.length > 0 ? () => handleOpenIT() : null}
      />

      <Row
        label="Payment Record"
        prop={+item.paid_amount <= 0 ? "Add Payment Record" : "-"}
        isClick={+item.paid_amount <= 0}
        callback={
          +item.paid_amount <= 0 ? () => handleOpenChangePayment() : null
        }
      />
      <Row label="Date Time" prop={dateFormateMMDDYYYHHMMSS(item.date_time)} />
      <Row
        label="Created At"
        prop={dateFormateMMDDYYYHHMMSS(item.created_at)}
      />
      {/*isLastRow should be true as it's last row and we want borderRadius there*/}

      {/* Invoices */}
      {openAddlink && (
        <AddInvoiceLinkOrder
          open={openAddlink}
          handleOnClose={handleCloseAddlink}
          row={item}
          id={item?.linkEdited?.length > 0 ? item?.linkEdited[0].id : 0}
          order_ids={item?.id ?? 0}
        />
      )}

      {openIT && (
        <ShowInvoiceTransaction
          open={openIT}
          handleClose={handleCloseIT}
          data={item.payments}
        />
      )}

      {openInfo && (
        <ShowLinkDetailsOrder
          open={openInfo}
          handleClose={handleCloseInfo}
          row={item?.linkEdited[0]}
        />
      )}

      {changeOpen && (
        <AddInvoicePayment
          open={changeOpen}
          id={item?.id ?? 0}
          handleOnClose={handleClosePaymentChange}
        />
      )}
    </Box>
  );
}

function Row({
  label,
  prop,
  isLastRow,
  isClick = false,
  callback = null,
  clickOnDetails = null,
  showDetails = false,
}) {
  return (
    <Stack width="100%" direction="row">
      <Box
        width="30%"
        bgcolor="#EFEFEF"
        borderBottom="1px solid white"
        display="flex"
        alignItems="center"
        paddingY="0.4rem"
        pl="0.8rem"
        sx={{ borderBottomLeftRadius: isLastRow && "5px" }}
      >
        <Typography fontSize="13px">{label}</Typography>
      </Box>
      <Box
        width="70%"
        bgcolor="white"
        borderBottom="1px solid #EFEFEF"
        display="flex"
        alignItems="center"
        paddingY="0.4rem"
        pl="0.8rem"
        justifyContent="space-between"
        sx={{
          borderBottomRightRadius: isLastRow && "5px",
        }}
      >
        <Box
          onClick={isClick ? callback : null}
          sx={{
            cursor: isClick ? "pointer" : "default",
            mr: 1.1,
          }}
        >
          <Typography
            fontSize="13px"
            fontWeight="500"
            sx={{
              color: isClick ? "#F8B03F" : "black",
              flex: 1,
            }}
          >
            {prop ? prop : "-"}
          </Typography>
        </Box>

        {showDetails && (
          <Typography
            onClick={() => clickOnDetails()}
            sx={{
              cursor: "pointer",
              color: "#F8B03F",
              fontSize: "12px",
              mr: 1,
            }}
          >
            View Details
          </Typography>
        )}
      </Box>
    </Stack>
  );
}

function DisplayReschedule({ id }) {
  const [data, setData] = useState([]);

  const getData = async () => {
    const d = await GET_USER_RH(id);
    if (d && d.s) {
      setData([...d.r]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {
        <Box
          sx={{
            display: "flex",
          }}
        >
          {data.map((v) => {
            return (
              <Chip
                key={v.id}
                size="small"
                sx={{ mr: 1, fontSize: "12px" }}
                label={dateFormateMMDDYYYHHMMSS(v.created_at)}
              />
            );
          })}
        </Box>
      }
    </Box>
  );
}

function ProviderView({
  data,
  setProviderId,
  handleClickPopMsg,
  anchorElMsg,
  getData,
}) {
  const [providerCharges, setProviderCharges] = useState({
    prodiders: data?.providers?.map((v) => v.id),
    citiesW: [],
    providerId: "",
    selectedProviders: data?.providers
      ?.filter((v) => v.isRemoveTransportCharge == 1)
      .map((v) => v.id),
  });

  const handleSelectCity = async (value) => {
    const check = providerCharges.selectedProviders.includes(value);
    let selectedProviders = [];
    // console.log(check);
    if (check) {
      selectedProviders = providerCharges.selectedProviders.filter(
        (v) => v != value
      );
    } else {
      selectedProviders.push(...providerCharges.selectedProviders);
      selectedProviders.push(value);
    }

    let c = [];
    let ids = [];
    if (selectedProviders.length) {
      c = data?.providers?.filter((v) =>
        selectedProviders.some((j) => j == v.id)
      );
      for (const i of c) {
        ids.push(i.id);
      }
      setProviderCharges((p) => ({
        ...p,
        providerId: ids.length ? ids.join(",") : "0",
        selectedProviders: selectedProviders,
      }));
    } else {
      setProviderCharges((p) => ({
        ...p,
        providerId: "0",
        selectedProviders: [],
      }));
    }
  };

  const handleSubmit = async () => {
    const d = await removedTransportProvider(
      data.id,
      providerCharges.providerId
    );

    if (d && d.s) {
      getData();
    } else {
      ToastShow(d?.m ?? "Opps! something went wrong. Please try again.");
      getData();
    }
  };

  useEffect(() => {
    if (providerCharges.providerId.length) {
      handleSubmit();
    }
  }, [providerCharges]);

  return data?.providers?.length ? (
    <Stack
      width="100%"
      bgcolor="white"
      boxShadow="3px 3px 7px 0px #00000040"
      sx={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}
    >
      {/*Header*/}
      <Box
        sx={{
          width: "100%",
          bgcolor: "#FFAB30",
          height: "2.370rem",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography fontSize="15px" fontWeight="500" ml="0.8rem">
          PODIAN Details
        </Typography>
      </Box>
      <Grid
        container
        columns={{ xs: 1, sm: 2, md: 3 }}
        columnGap={1}
        rowGap={1}
        my={1}
        ml={1}
      >
        {data?.providers?.map((v, index) => {
          return (
            <DetailsBox
              getData={getData}
              key={index}
              v={v}
              data={data}
              shootTime={data.date_time ?? null}
              handleClickPopMsg={handleClickPopMsg}
              setProviderId={setProviderId}
              anchorElMsg={anchorElMsg}
              providerCharges={providerCharges}
              handleSubmit={handleSubmit}
              handleSelectCity={handleSelectCity}
            />
          );
        })}
      </Grid>
      
    </Stack>
  ) : null;
}

const DetailsBox = ({
  getData,
  v,
  data,
  shootTime,
  handleClickPopMsg,
  setProviderId,
  anchorElMsg,
  providerCharges,
  handleSubmit,
  handleSelectCity,
  showOrderViewRights
}) => {
  const [open, setOpen] = useState(false);
  const [openSwap, setOpenSwap] = useState(false);
  const [selectedProviderId, setSelectedProviderId] = useState([]);
  const [openMsg, setOpenMsg] = useState(false);
  const selectedSwapProviderId = useRef(0);

  const [openAmountAdjust, setOpenAmoutAdjust] = useState(false);

  const [openSwapped, setOpenSwapped] = useState(false);

  const handleOnClose = (v) => {
    if (v) {
      getData();
    }
    setOpen(false);
  };

  const handleCloseMsg = async (v, msg) => {
    setOpenMsg(false);
    if (v) {
      setOpenSwap(false);

      const data = await swapProvider({
        user_id: selectedProviderId[0],
        orders_items_providers_id: selectedSwapProviderId.current,
        message: msg,
      });
      if (data && data.s) {
        getData();
      } else {
        ToastShow("Opps something went wrong. Please try again.");
      }
    }
  };

  const handleOnCloseSwap = (v) => {
    // if (v) {
    //   getData();
    // }
    setOpenSwap(false);
  };

  const handleSubmitSwap = (selectedProId, selectedSwapProId) => {
    if (selectedProId?.length > 0) {
      setSelectedProviderId(selectedProId);
      selectedSwapProviderId.current = selectedSwapProId;
      setOpenMsg(true);
    }
  };

  const getStatus = (s) => {
    // console.log(s,"Status");

    switch (s) {
      case 0:
        return "Not interested";
      case 1:
        return "Interested";
      case 2:
        return "Approved";
      case 3:
        return "Started work";
      case 4:
        return "Finish work";
      case 5:
        return "Submitted work";
      case 6:
        return "Completed";
    }
  };

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [val, setVal] = useState(null);

  const onDelete = async (v, id) => {
    handleOpenConfirmation(v, id);
  };

  const handleOpenConfirmation = (v, id) => {
    setSelectedId(id);
    setVal(v);
    setOpenConfirmation(true);
  };

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      const d = await APPROVE_REJECT_EXTENDS(val, selectedId);

      if (d && d.s) {
        ToastShow(d.m);
        getData();
      } else {
        ToastShow(d.m);
      }
    }
    setSelectedId(0);
    setVal(null);
    setOpenConfirmation(false);
  };

  const approveReject = async (v, id) => {
    const d = await APPROVE_REJECT_EXTENDS(v, id);

    if (d && d.s) {
      ToastShow(d.m);
      getData();
    } else {
      ToastShow(d.m);
    }

    setSelectedId(0);
    setOpenConfirmation(false);
  };

  function makeShootDate(d) {
    const date = new Date(d);
    const options = {
      day: "2-digit",
      month: "long",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    const [formattedDay, formattedMonth, formattedYear] =
      formattedDate.split(" ");
    const finalFormattedDate = [
      formattedDay,
      formattedMonth,
      formattedYear,
    ].join("-");
    return finalFormattedDate;
  }

  function formatTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

    // Ensure single-digit minutes are formatted with a leading zero
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  //For Report time
  const parsedTime = shootTime
    ? moment(dateFormateHHMMA(shootTime), "hh:mm A")
    : null;

  const reportTime = parsedTime
    ? parsedTime.subtract(v.report_time ?? 15, "minutes").format("h:mm A")
    : null;

  return (
    <Stack
      width="30%"
      bgcolor="#F6F6F6"
      borderRadius="5px"
      padding="0.3rem"
      gap="0.4rem"
    >
      {/*1st Container*/}
      <Stack
        direction="row"
        width="100%"
        height="2rem"
        paddingX="0.3rem"
        alignItems="center"
        justifyContent="space-between"
        bgcolor="white"
        borderRadius="5px"
      >
        <Typography fontSize="12.9px" fontWeight="500">
          {v.service_name}
        </Typography>

        <Stack direction="row" gap="0.5rem" height="88%">
          {v.swaped > 0 ? (
            <Typography
              color="#F89400"
              fontSize="11px"
              fontWeight="500"
              alignSelf="center"
              sx={{
                cursor: "pointer",
              }}
              onClick={() => setOpenSwapped(true)}
            >
              SWAPPED : {v.swaped}
            </Typography>
          ) : null}

          <Button
            sx={{
              borderRadius: "6px",
              backgroundColor: "#EFEFEF",
              color: "white",
              minWidth: "0",
              "&:hover": {
                bgcolor: "#bfbebb",
              },
            }}
            onClick={(e) => {
              setProviderId(v.user_id);
              handleClickPopMsg(e);
            }}
          >
            <img
              src={require("../../../Assets/Icons for pages/Agent.png")}
              alt=""
              style={{ objectFit: "fill" }}
            />
          </Button>

          <button
            style={{
              backgroundColor: "#FFD393",
              color: "black",
              fontSize: "10px",
              fontWeight: "500",
              paddingLeft: "0.7rem",
              paddingRight: "0.7rem",
              borderRadius: "5px",
              cursor: "default",
              textTransform: "uppercase",
            }}
          >
            {getStatus(v.status)}
          </button>
        </Stack>
      </Stack>

      {/*2nd Container*/}
      <Stack width="100%" gap="0.3rem">
        {/*Box 1*/}
        <Stack
          width="100%"
          height="4.2rem"
          padding="0.3rem"
          bgcolor="white"
          borderRadius="5px"
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Stack direction="row" height="37px" gap="0.5rem">
            <Avatar
              src={API_URL.baseUrl + API_URL.userProfileImage + v.photo}
            />
            <Stack height="100%" justifyContent="space-between">
              <Stack direction="row" gap="0.4rem">
                <Typography fontSize="11px" fontWeight="500">
                  {`${v.name} (#${v.user_id})`}
                </Typography>

                {/*Rating*/}
                <Stack direction="row" alignItems="center" gap="0.2rem">
                  <img
                    src={Rate}
                    style={{
                      width: "9.8px",
                      height: "9.8px",
                      objectFit: "fill",
                    }}
                    alt=""
                  />
                  <Typography fontSize="11px" fontWeight="500">
                    {`(${v.rate.toFixed(1)})`}
                  </Typography>
                </Stack>
              </Stack>

              <Typography fontSize="11px" fontWeight="500">
                {v.mobile}
              </Typography>
            </Stack>
          </Stack>
          {v.start_photo ? (
            <ShowSelfie
              photo={v.start_photo}
              lat={v.sw_latitude}
              lng={v.sw_longitude}
            />
          ) : null}
        </Stack>

        {/*Swap_r*/}
        {v.swap_r === 1 ? (
          <Stack
            width="100%"
            padding="0.3rem"
            bgcolor="white"
            borderRadius="5px"
            justifyContent="space-between"
          >
            <Stack gap="0.4rem">
              <Typography
                bgcolor="grey"
                px="0.4rem"
                color="white"
                fontSize="11px"
                textAlign="center"
                letterSpacing="1px"
              >
                Requested to Swap
              </Typography>

              {v.swap_r_note && (
                <Typography px="0.1rem" fontSize="11px">
                  NOTE: {v.swap_r_note}
                </Typography>
              )}
            </Stack>
          </Stack>
        ) : null}

        {/*Box 2*/}
        {v.added_by_name ||
          v.duration ||
          v.start_date_time ||
          v.support_time ||
          shootTime ||
          reportTime ? (
          <Stack
            width="100%"
            padding="0.3rem"
            bgcolor="white"
            borderRadius="5px"
            justifyContent="space-between"
          >
            {v.added_by_name && (
              <Stack direction="row" gap="4.6rem">
                <Typography color="#484848" fontSize="11px">
                  Added By
                </Typography>

                <Typography fontSize="11px" fontWeight="500">
                  {v.added_by_name}
                </Typography>
              </Stack>
            )}

            {v.start_date_time && (
              <Stack direction="row" gap="4.250rem">
                <Typography color="#484848" fontSize="11px">
                  Shoot Date
                </Typography>
                <Typography fontSize="11px" fontWeight="500">
                  {makeShootDate(v.start_date_time)}
                </Typography>
              </Stack>
            )}

            {v.start_date_time ? (
              <Stack direction="row" gap="2.430rem">
                <Typography color="#484848" fontSize="11px">
                  Shoot Start Time
                </Typography>
                <Typography fontSize="11px" fontWeight="500">
                  {formatTime(v.start_date_time)}
                </Typography>
              </Stack>
            ) : null}

            {v.end_date_time ? (
              <Stack direction="row" gap="2.730rem">
                <Typography color="#484848" fontSize="11px">
                  Shoot End Time
                </Typography>
                <Typography fontSize="11px" fontWeight="500">
                  {formatTime(v.end_date_time)}
                </Typography>
              </Stack>
            ) : null}

            {v.duration ? (
              <Stack direction="row" gap="4.9rem">
                <Typography color="#484848" fontSize="11px">
                  Duration
                </Typography>
                <Typography fontSize="11px" fontWeight="500">
                  {v.duration + " min."}
                </Typography>
              </Stack>
            ) : null}

            {v.support_time && (
              <Stack direction="row" gap="3.4rem">
                <Typography color="#484848" fontSize="11px">
                  Support Time
                </Typography>
                <Typography fontSize="11px" fontWeight="500">
                  {v.support_time + " minutes"}
                </Typography>
              </Stack>
            )}

            {reportTime && (
              <Stack direction="row" gap="3.850rem">
                <Typography color="#484848" fontSize="11px">
                  Report Time
                </Typography>
                <Typography fontSize="11px" fontWeight="500">
                  {reportTime}
                </Typography>
              </Stack>
            )}
          </Stack>
        ) : null}

        {/*Box 3*/}
        <Stack
          width="100%"
          padding="0.3rem"
          bgcolor="white"
          borderRadius="5px"
          justifyContent="space-between"
        >
          {data.latitude && data.longitude ? (
            <Stack direction="row" gap="2.8rem" alignItems="center">
              <Typography color="#484848" fontSize="11px">
                Shoot Location
              </Typography>
              <Button
                sx={{ gap: "0.3rem", padding: "0", margin: "0" }}
                onClick={() =>
                  window.open(
                    "https://maps.google.com?q=" +
                    data.latitude +
                    "," +
                    data.longitude
                  )
                }
              >
                <Typography fontSize="11px" textTransform="none">
                  Direction
                </Typography>
                <img
                  src={location_icon}
                  style={{ width: "9px", objectFit: "fill" }}
                  alt=""
                />
              </Button>
            </Stack>
          ) : null}

          <Stack direction="row" gap="2.2rem">
            <GetCurrentLocation id={v.user_id} />
          </Stack>
        </Stack>

        {/*Extend hours, price, distance charge etc.*/}
        {v.extend?.length ? (
          <Stack direction="row" gap="3.3rem">
            <Typography fontSize="11px" textTransform="none">
              Extend Hours
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {v.extend.map((e, i) => {
                return (
                  <Box
                    key={i}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "5rem",
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize="11px"
                      textTransform="none"
                      fontWeight="500"
                    >
                      {rightsUser.includes(9)
                        ? "₹" + e.psubtotal.toFixed(1)
                        : ""}
                      ({e.hours}hr-
                      {e.status == 1
                        ? "Approved"
                        : e.status == 0
                          ? "Pending"
                          : "Rejected"}
                      )
                    </Typography>

                    {e.status < 3 ? (
                      <SplitButton
                        key={1}
                        dataId={1}
                        title={"split"}
                        isClear={e.status == 0}
                        isDelete={e.status == 0 || e.status == 1}
                        deleteText={"Reject"}
                        onDelete={() => onDelete(-1, e.id)}
                        onClear={() => onDelete(1, e.id)}
                        clearText="Accept"
                        change={true}
                        clearIcon={<DoneOutline />}
                      />
                    ) : null}
                  </Box>
                );
              })}
            </Box>
          </Stack>
        ) : null}

        {/*Box 4*/}
        <Stack
          width="100%"
          padding="0.3rem"
          gap="0.2rem"
          bgcolor="white"
          borderRadius="5px"
          justifyContent="space-between"
        >
          {rightsUser.includes(9) ? (
            <>
              <Stack direction="row" gap="6.1rem">
                <Typography color="#484848" fontSize="11px">
                  Price
                </Typography>
                <Typography fontSize="11px" fontWeight="500">
                  {"₹" + v.price.toFixed(1)}
                </Typography>
              </Stack>

              {!v.isRemoveTransportCharge ? (
                <Stack direction="row" gap="2.4rem">
                  <Typography color="#484848" fontSize="11px">
                    Distance Charge
                  </Typography>

                  <Stack direction="row" alignItems="center" gap="10rem">
                    <Typography fontSize="11px" fontWeight="500">
                      {"₹" +
                        (v.isRemoveTransportCharge
                          ? 0
                          : data.distance1PCharges.toFixed(1)) +
                        ""}
                    </Typography>
                  </Stack>
                </Stack>
              ) : null}

              {/*Extend hr charge*/}
              {/* {v.extend.reduce(
                (a, c) => +a + +(c.status == 1 ? c.psubtotal : 0).toFixed(1),
                0
              ) != 0 ? (
                <DetailsView
                  title="Extends Hr Charge : "
                  description={
                    "₹" +
                    v.extend.reduce(
                      (a, c) =>
                        +a + +(c.status == 1 ? c.psubtotal : 0).toFixed(1),
                      0
                    )
                  }
                />
              ) : null} */}
              {v?.extend?.psubtotal && <Stack direction="row" gap="4.6rem">
                <Typography color="#484848" fontSize="11px">
                  Extended Hr Info
                </Typography>
                <Typography fontSize="11px" fontWeight="500">
                  {console.log('v', v)
                  }
                  {"₹" + v.extend?.psubtotal?.toFixed(1)}
                </Typography>
              </Stack>}
              <Stack direction="row" gap="4.6rem">
                <Typography color="#484848" fontSize="11px">
                  Sub-Total
                </Typography>
                <Typography fontSize="11px" fontWeight="500">
                  {"₹" + v.subtotal.toFixed(1)}
                </Typography>
              </Stack>

              <Stack direction="row" gap="3.6rem">
                <Typography color="#484848" fontSize="11px">
                  Total Earning
                </Typography>
                {/* <Typography fontSize="11px" fontWeight="500">
                  {"₹" +
                    v.extend
                      .reduce(
                        (a, c) =>
                          +a + +(c.status == 1 ? c.psubtotal : 0).toFixed(1),
                        +v.subtotal + +(v.isRemoveTransportCharge
                          ? 0
                          : data.distance1PCharges)
                      )
                      .toFixed(1)}
                </Typography> */}
                <Typography fontSize="11px" fontWeight="500">
                  {"₹" +
                    (
                      v.subtotal +
                      (v.isRemoveTransportCharge ? 0 : data.distance1PCharges) +
                      (v.extend && v.extend.length > 0
                        ? v.extend.reduce((a, c) => a + (c.status === 1 ? c.psubtotal : 0), 0)
                        : 0)
                    ).toFixed(1)}
                </Typography>
              </Stack>
            </>
          ) : null}
          <Button
            sx={{
              color: "#F89400",
              backgroundColor: "white",
              textTransform: "uppercase",
              fontSize: "11.5px",
              fontWeight: "500",
              padding: "0px 3px",
              alignSelf: "flex-start",
            }}
            onClick={() => setOpenAmoutAdjust(true)}
          >
            Amount Adjust
          </Button>
        </Stack>

        {/*Box 5*/}
        {v.no_pic || v.no_clip || v.client_remark || v.links.length ? (
          <Stack
            width="100%"
            padding="0.3rem"
            bgcolor="white"
            borderRadius="5px"
            gap="0.390rem"
          >
            {v.no_pic != null && (
              <Stack direction="row" gap="1.4rem">
                <Typography color="#484848" fontSize="11px">
                  No of submit Image
                </Typography>
                <Typography fontSize="11px" fontWeight="500">
                  {v.no_pic}
                </Typography>
              </Stack>
            )}

            {v.no_clip != null && (
              <Stack direction="row" gap="1.4rem">
                <Typography color="#484848" fontSize="11px">
                  No of submit Video
                </Typography>
                <Typography fontSize="11px" fontWeight="500">
                  {v.no_clip}
                </Typography>
              </Stack>
            )}

            {v.client_remark != null && (
              <Stack direction="row" gap="1.4rem">
                <Typography color="#484848" fontSize="11px">
                  Client Remarks
                </Typography>
                <Typography fontSize="11px" fontWeight="500">
                  {v.client_remark}
                </Typography>
              </Stack>
            )}

            {/*Links*/}
            {/* <Stack direction="row" justifyContent="space-between">
                <Button
                  sx={{
                    bgcolor: "#CEE5FF",
                    display: "flex",
                    alignItems: "center",
                    gap: "0.4rem",
                    width: "49%",
                    color: "#CEE5FF",
                    "&:hover": {
                      bgcolor: "#87a3c4",
                    },
                  }}
                  onClick={() => ""}
                >
                  <img
                    src={linkIcon}
                    style={{ objectFit: "fill", width: "10.8px" }}
                    alt=""
                  />
                  <Typography fontSize="11px" fontWeight="500">
                    LINK
                  </Typography>
                </Button>
              </Stack> */}
            {v.links.length ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {v.links.map((j, i) => {
                  return <SubmitLinkView key={i} data={j} />;
                })}
              </Box>
            ) : null}
          </Stack>
        ) : null}

        {/*Box 6*/}
        <Stack
          width="100%"
          padding="0.3rem"
          bgcolor="white"
          borderRadius="5px"
          gap="0.250rem"
        >
          <FormControlLabel
            sx={{
              display: "flex",
              alignItems: "center",
              "& .MuiSvgIcon-root": { fontSize: '"0.750rem"' },
              "& .MuiFormControlLabel-label": { fontSize: "12px" },
              "& .css-7jdnmo-MuiTypography-root": {
                color: providerCharges.selectedProviders.includes(v.id)
                  ? "#F8AF41"
                  : "inherit",
                userSelect: "none",
              },
            }}
            style={{ margin: "0px" }}
            label="Is Transport Charges Removed ?"
            control={
              <Checkbox
                checked={providerCharges.selectedProviders.includes(v.id)}
                onChange={(e) => handleSelectCity(v.id)}
                icon={
                  <img
                    style={{
                      width: ".9rem",
                      objectFit: "fill",
                    }}
                    src={require("../../../Assets/UnCheckedIcon.png")}
                  />
                }
                checkedIcon={
                  <img
                    style={{
                      width: ".9rem",
                      objectFit: "fill",
                    }}
                    src={require("../../../Assets/Icons for pages/checked_icon.png")}
                  />
                }
              />
            }
          />
        </Stack>

        {/*Box 7*/}
        {v.review && (
          <Stack
            width="100%"
            padding="0.3rem"
            bgcolor="white"
            borderRadius="5px"
            gap="0.250rem"
          >
            {v.review && (
              <Rating
                name="read-only"
                value={v.review.rate}
                readOnly
                size="small"
              />
            )}

            {v.review && (
              <Typography fontSize="11px" color="#595959">
                {v.review.review}
              </Typography>
            )}
          </Stack>
        )}

        <Button
          onClick={() => setOpenSwap(true)}
          sx={{
            bgcolor: "#CEE5FF",
            display: "flex",
            alignItems: "center",
            gap: "0.4rem",
            width: "100%",
            color: "black",
            "&:hover": {
              bgcolor: "#87a3c4",
            },
          }}
        >
          <img src={swapIcon} style={{ objectFit: "fill", width: "10.8px" }} />
          <Typography fontSize="11px" fontWeight="500">
            Swap
          </Typography>
        </Button>

        {showOrderViewRights?.some((elem) => elem?.name === "can change podian status" && elem?.id === 20) && (
          <Button
          onClick={() => setOpen(true)}
          sx={{
            bgcolor: "#CEE5FF",
            display: "flex",
            alignItems: "center",
            gap: "0.4rem",
            width: "100%",
            color: "black",
            "&:hover": {
              bgcolor: "#87a3c4",
            },
          }}
        >
          <img
            src={change_icon}
            style={{ objectFit: "fill", width: "10.8px" }}
          />
          <Typography fontSize="11px" fontWeight="500">
            Change Status
          </Typography>
        </Button>
        )}
      </Stack>

      {open && (
        <ChangeStatus
          handleOnClose={handleOnClose}
          open={open}
          statusV={getStatus(v.status)}
          id={v.id}
        />
      )}

      {openSwap && (
        <OrderSwapDailog
          qty={1}
          service="Swap"
          handleClose={handleOnCloseSwap}
          open={openSwap}
          userID={v.id}
          handleSubmit={handleSubmitSwap}
          orderItemId={v.orders_items_id}
          selectedUserID={v.user_id}
        />
      )}

      {openSwapped && (
        <SwappedDailog
          handleClose={() => setOpenSwapped(false)}
          open={openSwapped}
          id={v.swap_ref_id}
          service_name={v.service_name}
        />
      )}

      {openSwap && (
        <CancelDailog
          msg="Message"
          open={openMsg}
          handleOnClose={handleCloseMsg}
          title="Notification"
          content="Add message to send the notification to the PODIAN who is going to remove from shoot."
        />
      )}

      {openConfirmation ? (
        <ConfirmationDailog
          title={val == 1 ? "Approve extends hours!" : "Delete extends hours !"}
          content={`Are you sure, You want to ${val == 1 ? "approve" : "delete"
            } the extends hours?`}
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}

      {openAmountAdjust ? (
        <AmountAdjustModal
          providerID={v.id}
          getData={getData}
          open={openAmountAdjust}
          handleClose={() => setOpenAmoutAdjust(false)}
        />
      ) : null}
    </Stack>
  );
};

// function ShowProviderDetails({
//   providerCharges,
//   handleSelectCity,
//   handleSubmit,
//   v,
//   handleClickPopMsg,
//   setProviderId,
//   anchorElMsg,
//   getData,
//   data,
// }) {
//   const [open, setOpen] = useState(false);
//   const [openSwap, setOpenSwap] = useState(false);
//   const [selectedProviderId, setSelectedProviderId] = useState([]);
//   const [openMsg, setOpenMsg] = useState(false);
//   const selectedSwapProviderId = useRef(0);

//   const handleOnClose = (v) => {
//     if (v) {
//       getData();
//     }
//     setOpen(false);
//   };

//   const handleCloseMsg = async (v, msg) => {
//     setOpenMsg(false);
//     if (v) {
//       setOpenSwap(false);

//       const data = await swapProvider({
//         user_id: selectedProviderId[0],
//         orders_items_providers_id: selectedSwapProviderId.current,
//         message: msg,
//       });
//       if (data && data.s) {
//         getData();
//       } else {
//         ToastShow("Opps something went wrong. Please try again.");
//       }
//     }
//   };

//   const handleOnCloseSwap = (v) => {
//     // if (v) {
//     //   getData();
//     // }
//     setOpenSwap(false);
//   };

//   const handleSubmitSwap = (selectedProId, selectedSwapProId) => {
//     if (selectedProId?.length > 0) {
//       setSelectedProviderId(selectedProId);
//       selectedSwapProviderId.current = selectedSwapProId;
//       setOpenMsg(true);
//     }
//   };

//   const getStatus = (s) => {
//     // console.log(s,"Status");

//     switch (s) {
//       case 0:
//         return "Not interested";
//       case 1:
//         return "Interested";
//       case 2:
//         return "Approved";
//       case 3:
//         return "Started work";
//       case 4:
//         return "Finish work";
//       case 5:
//         return "Submitted work";
//       case 6:
//         return "Completed";
//     }
//   };

//   const [openConfirmation, setOpenConfirmation] = useState(false);
//   const [selectedId, setSelectedId] = useState(0);
//   const [val, setVal] = useState(null);

//   const onDelete = async (v, id) => {
//     handleOpenConfirmation(v, id);
//   };

//   const handleOpenConfirmation = (v, id) => {
//     setSelectedId(id);
//     setVal(v);
//     setOpenConfirmation(true);
//   };

//   const handleOnCloseConfirmation = async (v) => {
//     if (v) {
//       const d = await APPROVE_REJECT_EXTENDS(val, selectedId);

//       if (d && d.s) {
//         ToastShow(d.m);
//         getData();
//       } else {
//         ToastShow(d.m);
//       }
//     }
//     setSelectedId(0);
//     setVal(null);
//     setOpenConfirmation(false);
//   };

//   const approveReject = async (v, id) => {
//     const d = await APPROVE_REJECT_EXTENDS(v, id);

//     if (d && d.s) {
//       ToastShow(d.m);
//       getData();
//     } else {
//       ToastShow(d.m);
//     }

//     setSelectedId(0);
//     setOpenConfirmation(false);
//   };

//   console.log("v", v);

//   return (
//     <>
//       <Paper
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "space-between",
//         }}
//       >
//         <Box
//           display="flex"
//           flex={1}
//           flexDirection="column"
//           justifyContent="start"
//           alignItems="start"
//           pl="10px"
//           pr="10px"
//           pt="10px"
//         >
//           <Avatar src={API_URL.baseUrl + API_URL.userProfileImage + v.photo} />

//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "start",
//               alignItems: "center",
//               width: "100%",
//             }}
//           >
//             <Typography>{v.name}</Typography>
//             <IconButton
//               anchorEl={anchorElMsg}
//               size="small"
//               onClick={(e) => {
//                 setProviderId(v.user_id);
//                 handleClickPopMsg(e);
//               }}
//             >
//               <Message sx={{ height: "20px" }} />
//             </IconButton>
//           </Box>
//           <Typography variant="subtitle2">{v.service_name}</Typography>
//           {v.mobile ? (
//             <DetailsView title="Mobile : " description={v.mobile} />
//           ) : null}

//           {v.duration ? (
//             <DetailsView
//               title="Duration : "
//               description={v.duration + " min."}
//             />
//           ) : null}

//           <DetailsViewDateTime
//             title="Start D/T : "
//             description={v.start_date_time}
//           />
//           <DetailsViewDateTime
//             title="End D/T : "
//             description={v.end_date_time}
//           />

//           {v.support_time ? (
//             <DetailsView
//               title="Support Time : "
//               description={v.support_time + " minutes"}
//             />
//           ) : null}

//           {data.latitude && data.longitude ? (
//             <Button
//               sx={{ p: 0 }}
//               onClick={() =>
//                 window.open(
//                   "https://maps.google.com?q=" +
//                     data.latitude +
//                     "," +
//                     data.longitude
//                 )
//               }
//             >
//               <Typography variant="subtitle2"> Go to</Typography>{" "}
//               <LocationOn sx={{ hight: "10px", color: colors.blue }} />
//             </Button>
//           ) : null}

//           <GetCurrentLocation id={v.user_id} />

//           {rightsUser.includes(9) ? (
//             <>
//               {v.extend?.length ? (
//                 <Box>
//                   <Typography variant="subtitle2">Extends Hours</Typography>
//                   <Box sx={{ display: "flex", flexWrap: "wrap" }}>
//                     {v.extend.map((e) => {
//                       return (
//                         <Box
//                           sx={{
//                             justifyContent: "center",
//                             alignItems: "center",
//                             border: "solid",
//                             borderColor: "#c0c0c0",
//                             borderRadius: "10px",
//                             p: 1,
//                             m: 1,
//                           }}
//                         >
//                           <Chip
//                             sx={{ mr: 0.3, mb: 0.3 }}
//                             size="small"
//                             label={
//                               `${e.hours}hr` +
//                               `(₹${e.psubtotal.toFixed(1)}) - ${
//                                 e.status == 1
//                                   ? "Approved"
//                                   : e.status == 0
//                                   ? "Pending"
//                                   : "Rejected"
//                               } `
//                             }
//                             key={e.id}
//                           />
//                           {e.status < 3 ? (
//                             <Box
//                               sx={{
//                                 display: "flex",
//                                 justifyContent: "center",
//                               }}
//                             >
//                               {e.status == 0 && (
//                                 <IconButton onClick={() => onDelete(1, e.id)}>
//                                   <Check color="success" />
//                                 </IconButton>
//                               )}

//                               {(e.status == 0 || e.status == 1) && (
//                                 <IconButton onClick={() => onDelete(-1, e.id)}>
//                                   <Clear color="error" />
//                                 </IconButton>
//                               )}
//                             </Box>
//                           ) : null}
//                         </Box>
//                       );
//                     })}
//                   </Box>
//                 </Box>
//               ) : null}
//               <DetailsView
//                 title="Price : "
//                 description={"₹" + v.price.toFixed(1) + ""}
//               />

//               <DetailsView
//                 title="Subtotal : "
//                 description={"₹" + v.subtotal.toFixed(1) + ""}
//               />

//               {!v.isRemoveTransportCharge ? (
//                 <DetailsView
//                   title="Distance Charge : "
//                   description={
//                     "₹" +
//                     (v.isRemoveTransportCharge
//                       ? 0
//                       : data.distance1PCharges.toFixed(1)) +
//                     ""
//                   }
//                 />
//               ) : null}

//               {v.extend.reduce(
//                 (a, c) => +a + +(c.status == 1 ? c.psubtotal : 0).toFixed(1),
//                 0
//               ) != 0 ? (
//                 <DetailsView
//                   title="Extends Hr Charge : "
//                   description={
//                     "₹" +
//                     v.extend.reduce(
//                       (a, c) =>
//                         +a + +(c.status == 1 ? c.psubtotal : 0).toFixed(1),
//                       0
//                     )
//                   }
//                 />
//               ) : null}

//               <DetailsView
//                 sx={{ fontWeight: "bold", fontSize: 16 }}
//                 title="Total Earning : "
//                 description={
//                   "₹" +
//                   v.extend
//                     .reduce(
//                       (a, c) =>
//                         +a + +(c.status == 1 ? c.psubtotal : 0).toFixed(1),
//                       +v.subtotal +
//                         +(v.isRemoveTransportCharge
//                           ? 0
//                           : data.distance1PCharges)
//                     )
//                     .toFixed(1)
//                 }
//               />
//             </>
//           ) : null}

//           {v.support_time ? (
//             <DetailsView
//               title="Support Time : "
//               description={v.support_time + " min."}
//             />
//           ) : null}

//           {v.no_pic != null ? (
//             <DetailsView title="No of images : " description={v.no_pic + ""} />
//           ) : null}
//           {v.no_clip != null ? (
//             <DetailsView
//               title="No of video clips : "
//               description={v.no_clip + ""}
//             />
//           ) : null}
//           {v.client_remark != null ? (
//             <DetailsView
//               title="Remarks from client : "
//               description={v.client_remark + ""}
//             />
//           ) : null}
//           {v.links.length ? (
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//               }}
//             >
//               {v.links.map((j, i) => {
//                 return <SubmitLinkView key={i} data={j} />;
//               })}
//             </Box>
//           ) : null}
//           {v.review && (
//             <Typography variant="subtitle2">{v.review.review}</Typography>
//           )}
//           {v.review && (
//             <Rating value={v.review.rate} size="small" sx={{ mb: 1 }} />
//           )}

//           <DetailsView title="Status: " description={getStatus(v.status)} />
//           <FormControlLabel
//             sx={{
//               display: "flex",
//               alignItems: "center",
//             }}
//             label="Is Transport Charges Removed ?"
//             control={
//               <Checkbox
//                 checked={providerCharges.selectedProviders.includes(v.id)}
//                 onChange={(e) => handleSelectCity(v.id)}
//               />
//             }
//           />
//           <Box mb={1} display="flex" gap={1}>
//             <Button
//               variant="outlined"
//               onClick={() => setOpen(true)}
//               sx={{ m: 0 }}
//             >
//               Change Status
//             </Button>

//             <Button
//               variant="outlined"
//               onClick={() => setOpenSwap(true)}
//               sx={{ m: 0 }}
//             >
//               Swap
//             </Button>
//           </Box>
//         </Box>
//         <Divider />
//         {v.start_photo ? (
//           <ShowSelfie
//             photo={v.start_photo}
//             lat={v.sw_latitude}
//             lng={v.sw_longitude}
//           />
//         ) : null}
//       </Paper>
//       {open && (
//         <ChangeStatus
//           handleOnClose={handleOnClose}
//           open={open}
//           statusV={getStatus(v.status)}
//           id={v.id}
//         />
//       )}

//       {openSwap && (
//         <OrderSwapDailog
//           qty={1}
//           service="Swap"
//           handleClose={handleOnCloseSwap}
//           open={openSwap}
//           userID={v.id}
//           handleSubmit={handleSubmitSwap}
//           orderItemId={v.orders_items_id}
//           selectedUserID={v.user_id}
//         />
//       )}

//       {openSwap && (
//         <CancelDailog
//           msg="Message"
//           open={openMsg}
//           handleOnClose={handleCloseMsg}
//           title="Notification"
//           content="Add message to send the notification to the PODIAN who is going to remove from shoot."
//         />
//       )}

//       {openConfirmation ? (
//         <ConfirmationDailog
//           title={val == 1 ? "Approve extends hours!" : "Delete extends hours !"}
//           content={`Are you sure, You want to ${
//             val == 1 ? "approve" : "delete"
//           } the extends hours?`}
//           open={openConfirmation}
//           handleOnClose={handleOnCloseConfirmation}
//         />
//       ) : null}
//     </>
//   );
// }

function GetCurrentLocation({ id }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    onSnapshot(doc(db, "provider_location/" + id), (doc) => {
      const t = doc?.data();
      setData(t);
      // console.log("location", t, "user_id", id);
    });
  }, []);
  return data != null ? (
    <Stack direction="row" gap="2.3rem">
      <Typography color="#484848" fontSize="11px">
        Current Location
      </Typography>

      <Button
        sx={{ gap: "0.3rem", padding: "0", margin: "0" }}
        onClick={() =>
          window.open("https://maps.google.com?q=" + data.lat + "," + data.lng)
        }
      >
        <Typography fontSize="11px" textTransform="none">
          Last Seen
        </Typography>
        <img
          src={location_icon}
          style={{ width: "9px", objectFit: "fill" }}
          alt=""
        />
      </Button>
    </Stack>
  ) : null;
}

function SubmitedLink({ status, value, id, getData }) {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [raw, setRaw] = useState(null);

  const handleCloseDelete = async (v) => {
    if (v) {
      // delete
      await DELETE_LINK(raw.id);
      getData();
    }
    setRaw(null);
    setDeleteOpen(false);
  };

  const handleOpenDelete = (v) => {
    setRaw(v);
    setDeleteOpen(true);
  };

  const handleClose = (v) => {
    if (v) {
      getData();
    }
    setRaw(null);
    setOpen(false);
  };

  const handleOpen = (v) => {
    setRaw(v);
    setOpen(true);
  };

  return status >= 5 ? (
    <Box display="flex" flexDirection="column">
      {value?.length === 0 && (
        <CustomerSubmitLink handleOpenAddLink={handleOpen} open={open} />
      )}

      <Box display="flex" flexWrap="wrap" gap="1rem">
        {value?.length > 0 &&
          value.map((v, i) => {
            return (
              <AlreadySubmitted
                key={i}
                v={v}
                value={value}
                handleOpenAddLink={handleOpen}
                handleOpenEditLink={() => handleOpen(v)}
                handleOpenDelete={() => handleOpenDelete(v)}
              />
            );
          })}
      </Box>

      {open && (
        <AddLinkOrder
          open={open}
          handleOnClose={handleClose}
          row={raw}
          id={raw?.id ?? 0}
          order_ids={id}
        />
      )}

      {deleteOpen && (
        <ConfirmationDailog
          handleOnClose={handleCloseDelete}
          open={deleteOpen}
          content="Are you sure, You want to delete this link?"
          title="Delete the link!"
        />
      )}

      {/* {d.map((v, i) => {
        return (
          <Box key={i} display="flex">
            <Link
              target="_blank"
              component="button"
              variant="body2"
              onClick={() => {
                window.open(v);
              }}
            >
              <Typography sx={{ maxLines: 1, color: "primary.main" }}>
                {" "}
                {v}
              </Typography>
            </Link>
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(v);
                ToastShow("Copied");
              }}
            >
              <ContentCopy />
            </IconButton>

            <IconButton
              onClick={async () => {
                const t = d.filter((p) => p != v);
                await submitEditedLink(id, t);
                getData();
              }}
            >
              <Delete />
            </IconButton>
          </Box>
        );
      })} */}
    </Box>
  ) : null;
}

function SubmitLinkView({ data }) {
  return (
    <Stack direction="row" gap="1rem" mb={1}>
      <Button
        sx={{
          bgcolor: "#CEE5FF",
          display: "flex",
          alignItems: "center",
          gap: "0.4rem",
          width: "100%",
          "&:hover": {
            bgcolor: "#87a3c4",
          },
        }}
        onClick={() => {
          window.open(data.link, "_blank");
        }}
      >
        <Typography fontSize="11px" fontWeight="500">
          OPEN LINK
        </Typography>
      </Button>

      <Button
        sx={{
          bgcolor: "#CEE5FF",
          display: "flex",
          alignItems: "center",
          gap: "0.4rem",
          width: "100%",
          "&:hover": {
            bgcolor: "#87a3c4",
          },
        }}
        onClick={() => {
          navigator.clipboard.writeText(data.link);
          ToastShow("Copied");
        }}
      >
        {/* <img
          src={linkIcon}
          style={{ objectFit: "fill", width: "10.8px" }}
          alt=""
        /> */}
        <Typography fontSize="11px" fontWeight="500">
          COPY LINK
        </Typography>
      </Button>
    </Stack>
  );
}

{
  /*Old SubmitLinkView*/
}
// function SubmitLinkView({ data }) {
//   return (
//     <Box display="flex" alignItems="center">
//       <Link
//         target="_blank"
//         component="button"
//         variant="body2"
//         onClick={() => {
//           window.open(data.link);
//         }}
//       >
//         <Typography sx={{ maxLines: 1, color: "primary.main" }}>
//           {data.link}
//         </Typography>
//       </Link>

//       <IconButton
//         onClick={() => {
//           navigator.clipboard.writeText(data.link);
//           ToastShow("Copied");
//         }}
//       >
//         <ContentCopy />
//       </IconButton>

//       <Typography
//         sx={{
//           fontSize: "12px",
//         }}
//       >
//         ({format(Date.parse(data.created_at), "dd-MM-yyyy hh:mm a")})
//       </Typography>
//     </Box>
//   );
// }

function DetailsView({ title, description, sx = {} }) {
  return description ? (
    <Box display="flex" alignItems="center">
      <Typography variant="subtitle2" sx={{ ...sx }}>
        {title}
      </Typography>
      <Typography sx={{ ml: "3px", ...sx }} variant="subtitle2">
        {description}
      </Typography>
    </Box>
  ) : null;
}

function DetailsViewDateTime({ title, description }) {
  return description ? (
    <Box display="flex" alignItems="center">
      <Typography variant="subtitle2">{title}</Typography>
      <Typography variant="subtitle2" sx={{ ml: "5px", fontSize: "13px" }}>
        {description ? dateFormateMMDDYYY(description) : "-"}
      </Typography>
      <Typography variant="subtitle2" sx={{ ml: 1, fontSize: "13px" }}>
        {description ? dateFormateHHMMA(description) : "-"}
      </Typography>
    </Box>
  ) : null;
}

function ShowSelfie({ photo, lat, lng }) {
  return (
    <Box
      flex={1}
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      borderRadius="5px"
    >
      <Box
        sx={{
          cursor: "pointer",
        }}
        component={"div"}
        onClick={() => {
          window.open(API_URL.baseUrl + API_URL.selfieImage + photo);
        }}
      >
        <img
          height="55px"
          width="60px"
          style={{ borderRadius: "5px", objectFit: "cover" }}
          src={API_URL.baseUrl + API_URL.selfieImage + photo}
          alt="logo"
        />
      </Box>
    </Box>
  );
}
