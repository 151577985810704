import {
  Avatar,
  Button,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GET_CITIE } from "../../../Apis/city_api";
import { API_URL } from "../../../Apis/config";
import { GETROLE } from "../../../Apis/podian_api";
import { GETPROVIDERS } from "../../../Apis/service_api";
import SplitButton from "../../../Component/Button/SplitButton";
import DateRangeSelection from "../../../Component/Dailog/DateRangeSelection";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import BOD from "../../../Component/Date/BOD";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import multiselectionIcon from "../../../Assets/multiselectionIcon.png";
import Rate from "../../../Assets/Rate.png";
import DataGridCover from "../../../Component/DataGridCover/DataGridCover";
import TextFieldSmall from "../../../Component/TextFields/TextFieldSmall";
import { ToastShow } from "../../../Component/Toast/Toast";
import { fi } from "date-fns/locale";
import { GET_MY_ROLES } from "../../../Apis/users_api";

let names = [];
const status = ["All", "Pending", "Active", "Rejected"];
const onBoardStatus = [
  "All",
  "In Progress",
  "Completed",
  "Approved",
  "Pending From Web",
];
const roles = ["All", "Photographer", "Cinematographer"];
let podianRole = { r: [] };
let searchOuter = "";
let selectedCityOuter = "All";
let cityOuter = 0;
let onBoardStatusOuter = "All";
let roleStatusOuter = "All";
let selectedIdOuter = ["All"];

function ServiceProviderList() {
  const navigator = useNavigate();

  const [ids, setIds] = useState([]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      filterable: false,
      headerAlign: "center",
      align: "center",
      width: 70,
      minWidth: 70,
      flex: 1,
    },
    {
      field: "photo",
      headerName: "Profile",
      headerAlign: "center",
      align: "center",
      width: 100,
      minWidth: 100,
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      renderCell: (params) => (
        <Avatar
          sx={{ width: "32px", height: "32px" }}
          src={API_URL.baseUrl + API_URL.userProfileImage + params.value}
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 120,
      minWidth: 120,
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "12px", textAlign: "center" }}>
          {params.row.first_name + " " + params.row.last_name}
        </Typography>
      ),
    },
    {
      field: "rate",
      headerName: "Rate",
      headerAlign: "center",
      align: "center",
      width: 80,
      minWidth: 80,
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      renderCell: (params) => (
        <Box
          gap=".3rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography sx={{ fontSize: "12px", textAlign: "center" }}>
            {params.row.avg_rate > 0
              ? params.row.avg_rate.toFixed(1)
              : params.row.avg_rate}
          </Typography>
          <img src={Rate} style={{ width: "12px", objectFit: "fill" }} alt="" />
        </Box>
      ),
    },
    {
      field: "role",
      headerName: "Role",
      headerAlign: "center",
      align: "center",
      width: 325,
      minWidth: 325,
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      renderCell: (params) => {
        const d = [];
        for (const v of params.row.roles) {
          // console.log("role",v.name);
          // console.log(v.name);
          d.push(v.name);
          if (d.length === 3) break;
        }
        return (
          <Box display="flex" gap=".1rem">
            {d.map((i, k) => {
              if (k === 0) {
                return (
                  <Typography
                    key={k}
                    bgcolor="#CEFFDC"
                    color="#101010"
                    fontSize="10.4px"
                    fontWeight="500"
                    py="2px"
                    px="5px"
                  >
                    {i}
                  </Typography>
                );
              } else if (k === 1) {
                return (
                  <Typography
                    key={k}
                    bgcolor="#FFCECE"
                    color="#101010"
                    fontSize="10.4px"
                    fontWeight="500"
                    py="2px"
                    px="5px"
                  >
                    {i}
                  </Typography>
                );
              } else {
                return (
                  <Typography
                    key={k}
                    bgcolor="#FFEBCE"
                    color="#101010"
                    fontSize="10.4px"
                    fontWeight="500"
                    py="2px"
                    px="5px"
                  >
                    {i}
                  </Typography>
                );
              }
            })}
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "12.4px" }}>{params.row.email}</Typography>
      ),
    },
    {
      field: "mobile",
      headerName: "Phone",
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "12.4px", textAlign: "center" }}>
          {params.row.mobile}
        </Typography>
      ),
    },
    {
      field: "schedule",
      headerName: "Schedule",
      width: 110,
      flex: 1,
      minWidth: 110,
      headerAlign: "center",
      align: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      renderCell: (params) => (
        <Button
          onClick={() => {
            setSelectedUserId(params.row.acutal_id);
            setOpen(true);
          }}
          sx={{
            width: "53px",
            height: "28px",
            fontSize: "12.9px",
            bgcolor: "#FFD393",
            color: "#101010",
            borderRadius: "5px",
            textTransform: "none",
            "&:hover": { bgcolor: "#FFD393" },
          }}
        >
          Check
        </Button>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 70,
      flex: 1,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          isEdit={null}
          isDelete={null}
          isView={1}
          onView={() =>
            window.open("/service-provider/list/" + params.row.acutal_id)
          }
          title={params.value}
        />
      ),
    },
  ];

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingGride, setIsLoadingGride] = useState(true);
  const [isLoadingRole, setIsLoadingRole] = useState(true);
  const [isCall, setIsCall] = useState(true);
  const [selected, setSelected] = useState(selectedIdOuter);
  const [statusValue, setStatus] = useState([roleStatusOuter]);
  const [boardStatusValue, setBoardStatus] = useState([onBoardStatusOuter]);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [open, setOpen] = useState(false);

  const [roleData, setRoleData] = useState([]);
  const [minCutOff, setMinCutOff] = useState("");

  const modifiedD = rows?.map((item) => ({
    ...item,
    acutal_id: item.id,
  }));

  const getData = async ({
    role = [],
    statusV = "All",
    search = "",
    counts = 0,
    boardStatus = 0,
    cityId = 0,
    minCutOff,
  }) => {
    let s = 2;
    let r = "";

    setIsLoadingGride(true);

    if (statusV !== "All") {
      // console.log(statusV);
      s = getStatusByValue(statusV);
    }

    if (role.length) {
      r = getRoleByValue(role, podianRole);
    }

    // console.log(rows.length,"=",s, " =",r)

    const data = await GETPROVIDERS({
      count: counts,
      status: s < 2 ? s : 2,
      role: r,
      search,
      boardStatus,
      cityId,
      min_cutoff: minCutOff,
    });
    if (!counts) {
      setRows([...(data ? data.r ?? [] : [])]);
    } else {
      setRows([...rows, ...(data ? data.r ?? [] : [])]);
    }

    const d = data.r?.map(({ id }) => id);
    setIds(d);

    setIsLoading(false);
    if ((data.r ?? []).length == 0) {
      setIsCall(false);
    } else if (!counts) {
      setIsCall(true);
    }
    setIsLoadingGride(false);
  };

  function getStatusByValue(v) {
    if (v === "Pending") {
      return 0;
    } else if (v === "Active") {
      return 1;
    } else if (v === "Rejected") {
      return -1;
    } else {
      return 2;
    }
  }

  function getRoleByValue(v, t) {
    let q = "";
    for (let d of t.r) {
      if (v.includes(d.name)) {
        q += d.id + ",";
      }
    }

    if (q.length) {
      q = q.substring(0, q.length - 1);
    }

    return q;
  }

  async function getRoles() {
    setIsLoadingRole(true);
    const d = await GETROLE();
    podianRole = d;

    for (let v of d.r) {
      names.push(v.name);
    }
    setIsLoadingRole(false);
  }

  useEffect(() => {
    getRoles();
    getData({
      role: selected,
      statusV: roleStatusOuter,
      cityId: cityOuter,
      search: searchOuter,
      boardStatus: getBoardStatus(onBoardStatusOuter),
      counts: 0,
    });
    getCities();
  }, []);

  const callBack = async (l) => {
    if (l >= rows.length && isCall) {
      await getData({
        role: selected,
        statusV: statusValue[0],
        search,
        boardStatus: getBoardStatus(boardStatusValue[0]),
        cityId: cityId,
        counts: rows.length,
      });
    }
  };

  const handleChange = async (e) => {
    const {
      target: { value },
    } = e;
    setSelected([...value]);
    selectedIdOuter = value;
    setIsLoading(true);
    await getData({
      counts: 0,
      role: value,
      statusV: statusValue[0],
      search,
      boardStatus: getBoardStatus(boardStatusValue[0]),
      cityId: cityId,
    });
  };

  const handleStatus = (e) => {
    const {
      target: { value },
    } = e;

    // console.log(value);
    setStatus([value]);
    roleStatusOuter = value;
    setIsLoading(true);
    getData({
      counts: 0,
      role: selected,
      statusV: value,
      search,
      boardStatus: getBoardStatus(boardStatusValue[0]),
      cityId: cityId,
    });
  };

  const handleBoardStatus = (e) => {
    const {
      target: { value },
    } = e;

    // console.log(value);
    setBoardStatus([value]);
    onBoardStatusOuter = value;
    setIsLoading(true);
    getData({
      counts: 0,
      role: selected,
      statusV: statusValue[0],
      search,
      boardStatus: getBoardStatus(value),
      cityId: cityId,
    });
  };

  const getBoardStatus = (v) => {
    if (v == "In Progress") {
      return 1;
    } else if (v == "Completed") {
      return 2;
    } else if (v == "Approved") {
      return 3;
    } else if (v == "Pending From Web") {
      return 4;
    } else {
      return 0;
    }
  };

  const [search, setSearch] = useState(searchOuter);

  const [selectedCities, setSelectedCities] = React.useState([
    selectedCityOuter,
  ]);
  const [cities, setCities] = React.useState([]);
  const [citiesW, setCitiesW] = React.useState([]);
  const [cityId, setSelectedId] = React.useState(cityOuter);

  const handleSelectCity = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCities([value]);
    selectedCityOuter = value;
    let c = [];
    if (value != "All") {
      c = citiesW.filter((v) => v.name == value);
    }
    setSelectedId(c.length ? c[0].id : 0);
    cityOuter = c.length ? c[0].id : 0;
    setIsLoading(true);
    getData({
      counts: 0,
      role: selected,
      statusV: statusValue[0],
      search,
      boardStatus: getBoardStatus(boardStatusValue[0]),
      cityId: c.length ? c[0].id : 0,
    });
  };

  async function getCities() {
    const t = await GET_CITIE({ count: 0, status: "1" });

    if (t && t.s == 1) {
      setCitiesW([...t.r]);
      const c = [];
      c.push("All");
      for (const v of t.r) {
        c.push(v.name);
      }
      setCities([...c]);
    }
  }

  const sendMinCutOff = () => {
    if (minCutOff !== "") {
      getData({
        counts: 0,
        role: selected,
        search,
        boardStatus: getBoardStatus(boardStatusValue[0]),
        cityId: cityId,
        minCutOff,
      });
    } else {
      ToastShow("Please enter a valid value!");
    }
  };

  const getMyRoles = async () => {
    const res = await GET_MY_ROLES();
    if (res && res.s) {
      setRoleData(res.r.auth);
    } else {
      ToastShow(res.m);
    }
  };

  const checkAuthRole = () => {
    return roleData?.some((item) => item.role === 4);
  };

  useEffect(() => {
    getMyRoles();
  }, []);
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="start"
    >
      <Box
        width="100%"
        height="63px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="16px"
      >
        <TextField
          size="small"
          label="Search"
          value={search}
          onChange={async (e) => {
            setSearch(e.target.value);
            searchOuter = e.target.value;
            setIsLoading(true);
            await getData({
              role: selected,
              counts: 0,
              statusV: statusValue[0],
              search: e.target.value,
              boardStatus: getBoardStatus(boardStatusValue[0]),
              cityId: cityId,
            });
          }}
          sx={{
            width: "10.7rem",
            height: "2.2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
            boxShadow: "2px 3px 4px rgba(0,0,0,0.2)",
            borderRadius: "6px",
            "& fieldset": { border: "none" },
            "& .MuiFormLabel-root": {
              color: "#969696",
            },
            "& .MuiFormLabel-root .Mui-focused": {
              color: "primary.main",
            },
            "& .css-1wk999l-MuiFormLabel-root-MuiInputLabel-root": {
              fontSize: "14px",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <img
                  src={require("../../../Assets/Search_icon.png")}
                  alt=""
                  style={{
                    width: "13.5px",
                    objectFit: "fill",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
        <Box display="flex" alignItems="center" gap=".7rem">
          {checkAuthRole() === true ? (
            <Box
              sx={{
                display: "flex",
                gap: "0.3rem",
                alignItems: "center",
              }}
            >
              <TextFieldSmall
                label="Min Cut-Off"
                placeholder="Enter minimum cut-off"
                value={minCutOff}
                onChange={(e) => setMinCutOff(e.target.value)}
                type="number"
                width="11rem"
                bgColor="white"
              />
              <Button
                sx={{
                  borderRadius: "0.4rem",
                  bgcolor: "#FFD393",
                  color: "black",
                  "&:hover": { bgcolor: "#FFD393" },
                }}
                onClick={sendMinCutOff}
              >
                SET
              </Button>
            </Box>
          ) : null}

          <MultiSelection
            multiple={false}
            names={cities}
            handleChange={handleSelectCity}
            selected={selectedCities}
            lable="Cities"
            icon={multiselectionIcon}
          />
          <MultiSelection
            selected={boardStatusValue}
            handleChange={handleBoardStatus}
            names={onBoardStatus}
            lable={"On Board Status"}
            multiple={false}
          />

          <MultiSelection
            selected={statusValue}
            handleChange={handleStatus}
            names={status}
            lable={"Role Status"}
            multiple={false}
          />

          {/* {isLoadingRole ? null : (
            <motion.div
              animate={{
                opacity: `${isLoadingRole ? 0 : 1}`,
              }}
            > */}
          <MultiSelection
            selected={selected}
            handleChange={handleChange}
            names={names}
            lable={"Role"}
            multiple={true}
          />
          {/* </motion.div>
          )} */}
        </Box>
      </Box>

      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <DataGridCover
          component={
            <DataGrideView
              cleanRows={false}
              rows={modifiedD ?? []}
              columns={columns}
              callBack={callBack}
              isLoading={isLoadingGride}
              colHeight={"56px"}
              sx={{ height: "69vh" }}
            />
          }
        />
      )}

      {open && (
        <DateRangeSelection
          open={open}
          handelOnClose={() => setOpen(false)}
          userId={selectedUserId}
        />
      )}
    </Box>
  );
}

export default ServiceProviderList;
