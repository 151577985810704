import { Stack, Typography } from "@mui/material";
import { useState, useRef } from "react";
import DataGrideView from "../../../../Component/DataGrideView/DataGrideView";
import ButtonForGrid from "../../../../Component/Button/ButtonForGrid";
import ButtonIcon2 from "../../../../Component/Button/ButtonIcon2";
import bell_icon from "../../../../Assets/Icons for pages/bell_icon.png";
import AssignModal from "./AssignModal/AssignModal";

const DataGrids = ({
  rowsPre,
  rowsMain,
  rowsPost,
  data_status,
  handleNotifyAgain,
  orderColumn,
}) => {
  const sampleAudio = process.env.PUBLIC_URL + "/sample_audio.mp3"; 
  return (
    <Stack
      sx={{
        bgcolor: "white",
        padding: "0.5rem",
        gap: "0.4rem",
        borderRadius: "5px",
        boxShadow: "3px 3px 7px 0px #00000040",
      }}
    >
      <Stack
        direction="row"
        sx={{
          bgcolor: "#F6F6F6",
          borderRadius: "5px",
          paddingY: "0.5rem",
          paddingX: "0.5rem",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography fontSize="18.4px" fontWeight="500">
          Order Items
        </Typography>

        {data_status > 0 && data_status !== 7 ? (
          <ButtonIcon2
            label="Notify Again"
            bgcolor="#FFAB30"
            paddingX="1.3rem"
            icon={bell_icon}
            imgSize="13px"
            onClick={() => handleNotifyAgain()}
          />
        ) : null}
      </Stack>

      <Typography fontSize="14.8px" fontWeight="500" mt={1}>
        Main Service
      </Typography>
      <DataGrideView
        rows={rowsMain}
        columns={orderColumn}
        colHeight={"3rem"}
        columnColor="#F6F6F6"
        hideFooter={true}
        autoHeight={true}
      />

      <Typography fontSize="14px" fontWeight="500" mt={1}>
        Pre-Order Add On’s
      </Typography>
      <DataGrideView
        rows={rowsPre}
        columns={orderColumn}
        colHeight={"2.8rem"}
        columnColor="#F6F6F6"
        hideFooter={true}
        autoHeight={true}
      />

      <Typography fontSize="14px" fontWeight="500" mt={1}>
        Post-Order Add On’s
      </Typography>
      <DataGrideView
        rows={rowsPost}
        columns={orderColumn}
        colHeight={"2.8rem"}
        columnColor="#F6F6F6"
        hideFooter={true}
        autoHeight={true}
      />
    </Stack>
  );
};

export default DataGrids;
